import { CONNECTIONS, FACEBOOK_AUTH, INSTA_AUTH1, SOMETHING_WENT_WRONG } from "../constants/URLConstants";
import { FACEBOOK_WORKPLATFORM_ID, PHYLLO_CONNECTED_ACCOUNT, PHYLLO_SESSION_EXPIRED_ACCOUNT } from "../constants/constants";
import { AllAccountsState } from "../context/AllAccountsContext";
import { AllAccountsActions, LoadingStates } from "./Reducers/Reducers";
import { isEmpty } from "./sdk_utils";

export const facebookFlowRedirection = async (sdkInstance, allAccounts, dispatchAllAccContext) => {
    // Redirecting to Facebook specific flow
    let connectedPlatformsDetails;
    // get accoints data from ctx or make call
    if (allAccounts.isLoading === LoadingStates.completed && !isEmpty(allAccounts.data)) {
        connectedPlatformsDetails = allAccounts.data;
    } else {
        dispatchAllAccContext({ type: AllAccountsActions.setLoadingTrue });
        connectedPlatformsDetails = await sdkInstance.getAllAccounts();
        dispatchAllAccContext({
            type: AllAccountsActions.addAllAccounts,
            payload: connectedPlatformsDetails,
        });
    }
    if (Object.keys(connectedPlatformsDetails).length === 0) return `/${SOMETHING_WENT_WRONG}`;
    const facebookConnectedAccounts = connectedPlatformsDetails.data.filter((item) => item.work_platform.id === FACEBOOK_WORKPLATFORM_ID);
    if (facebookConnectedAccounts.length === 0) return `/${FACEBOOK_AUTH}`;
    if (
        facebookConnectedAccounts.filter((item) => item.status === PHYLLO_CONNECTED_ACCOUNT).length > 0 ||
        connectedPlatformsDetails.data.filter((item) => item.status === PHYLLO_SESSION_EXPIRED_ACCOUNT).length > 0
    )
        return `/${CONNECTIONS}/${FACEBOOK_WORKPLATFORM_ID}`;
    return `/${FACEBOOK_AUTH}`;
};
