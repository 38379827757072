import { createContext, useContext, useReducer } from "react";
import { AllAccountsReducer, LoadingStates } from "../utils/Reducers/Reducers";

export const AllAccounts = createContext();

function AllAccountsContext({ children }) {
  const [allAccounts, dispatchAllAccContext] = useReducer(AllAccountsReducer, {
    isLoading: LoadingStates.pending,
    data: [],
  });

  return (
    <AllAccounts.Provider value={{ allAccounts, dispatchAllAccContext }}>
      {children}
    </AllAccounts.Provider>
  );
}

export const AllAccountsState = () => useContext(AllAccounts);

export default AllAccountsContext;
