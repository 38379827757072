import { CONNECTIONS, INSTA_AUTH1, SOMETHING_WENT_WRONG } from "../constants/URLConstants";
import { INSTA_WORKPLATFORM_ID, PHYLLO_CONNECTED_ACCOUNT, PHYLLO_SESSION_EXPIRED_ACCOUNT } from "../constants/constants";
import { AllAccountsState } from "../context/AllAccountsContext";
import { AllAccountsActions, LoadingStates } from "./Reducers/Reducers";
import { isEmpty } from "./sdk_utils";

export const instaFlowRedirection = async (sdkInstance, allAccounts, dispatchAllAccContext) => {
    // Redirecting to Instagram specific flow
    let connectedPlatformsDetails;
    // get accts data from ctx or make call
    if (allAccounts.isLoading === LoadingStates.completed && !isEmpty(allAccounts.data)) {
        connectedPlatformsDetails = allAccounts.data;
    } else {
        dispatchAllAccContext({ type: AllAccountsActions.setLoadingTrue });
        connectedPlatformsDetails = await sdkInstance.getAllAccounts();
        dispatchAllAccContext({
            type: AllAccountsActions.addAllAccounts,
            payload: connectedPlatformsDetails,
        });
    }

    if (Object.keys(connectedPlatformsDetails).length === 0) return `/${SOMETHING_WENT_WRONG}`;
    const instaConnectedAccounts = connectedPlatformsDetails.data.filter((item) => item.work_platform.id === INSTA_WORKPLATFORM_ID);
    if (instaConnectedAccounts.length === 0) return `/${INSTA_AUTH1}`;
    if (
        instaConnectedAccounts.filter((item) => item.status === PHYLLO_CONNECTED_ACCOUNT).length > 0 ||
        connectedPlatformsDetails.data.filter((item) => item.status === PHYLLO_SESSION_EXPIRED_ACCOUNT).length > 0
    )
        return `/${CONNECTIONS}/${INSTA_WORKPLATFORM_ID}`;
    return `/${INSTA_AUTH1}`;
};
