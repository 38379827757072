/* eslint-disable dot-notation */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    CONNECT_INSTAGRAM_VIA_FB_CLICK,
    FACEBOOK_SPECIAL_REQUIREMENTS,
    FB_HOW_TO,
    FB_LOGIN,
    FB_QUESTION_NO,
    FB_QUESTION_YES,
    HELP_GUIDE_CLICK,
    INSTAGRAM_FBPAGE_LINKED_NO,
    INSTAGRAM_FBPAGE_LINKED_YES,
    INSTAGRAM_HELP_GUIDE_CLICK,
    INSTAGRAM_INFO_Q1,
    INSTAGRAM_INFO_Q2,
    INSTAGRAM_PROFESSIONAL_ACCOUNT_NO,
    INSTAGRAM_PROFESSIONAL_ACCOUNT_YES,
    INSTAGRAM_SPECIAL_REQUIREMENT_1,
    INSTAGRAM_SPECIAL_REQUIREMENT_VIEW,
} from "../../analytics/eventConstants";
import { trackEvent } from "../../analytics/sdkAnalytics";
import Background from "../../components/Background/Background";
import { INSTA_AUTH2 } from "../../constants/URLConstants";
import Loader from "../../components/Loader/Loader";
import ScreenNavbar from "../../components/ScreenNavbar/ScreenNavbar";
import {
    FACEBOOK_HELP_GUIDE_URL,
    FACEBOOK_WORKPLATFORM_ID,
    FACEBOOK_WORKPLATFORM_NAME,
    INSTA_WORKPLATFORM_ID,
    INSTA_WORKPLATFORM_NAME,
    PHYLLO_EXIT_REASON_EXIT_FROM_PLATFORM,
} from "../../constants/constants";
import { AllAccountsState } from "../../context/AllAccountsContext";
import { SDKInstanceState } from "../../context/SdkInstanceContext";
import { SDKParamsState } from "../../context/SdkParmasContext";
import { getFlowURLFacebook, getFlowURLInsta } from "../../utils/getFlowURL";
import { ReactComponent as InfoIcon } from "../../assets/images/custom_info_icon.svg";
import login_button_fb from "../../assets/images/login_button_fb.svg";
import fb_logo from "../../assets/images/fb_logo.svg";
import "./FacebookAuthScreen.scss";
import { isEmpty } from "../../utils/sdk_utils";
import { LoaderState } from "../../context/LoaderContext";
import { LoaderActions } from "../../utils/Reducers/Reducers";
import { facebookLocalization } from "./translations";
import { useGetRedirectAuthFlow } from "../../utils/CustomHooks/useGetRedirectAuthFlow";
import { WorkPlatformDetailsState } from "../../context/WorkPlatformDetailsContext";
import { LoaderWithStatusState } from "../../context/LoaderWithStatusContext";
import LoaderWithStatus from "../../components/LoaderWithStatus/LoaderWithStatus";
import { ConfigState } from "../../context/ConfigContext";

function FacebookAuthScreen() {
    const navigate = useNavigate();
    const { sdkInstance } = SDKInstanceState();
    const { sdkParams } = SDKParamsState();
    const [facebookQuestionnaire, setFacebookQuestionnaire] = useState({
        question1: false,
    });
    const [backURL, setBackURL] = useState("");
    const [trackFirstQuestion, setTrackFirstQuestion] = useState(false);
    const { allAccounts, dispatchAllAccContext } = AllAccountsState();
    const { loader, dispatchLoaderContext } = LoaderState();
    const { configuration } = ConfigState();
    const [loaderMsgConfig, setLoaderMsgConfig] = useState(configuration.screens.short_loader_screen);
    const { isConnectLoader } = LoaderWithStatusState();
    const { workplatformDetails } = WorkPlatformDetailsState();
    const redirectAuthFlow = useGetRedirectAuthFlow(FACEBOOK_WORKPLATFORM_ID, FACEBOOK_WORKPLATFORM_NAME, sdkParams, workplatformDetails);
    const checkQuestionResponse = () => {
        if (facebookQuestionnaire.question1) {
            // Tracking event : CONNECT_INSTAGRAM_VIA_FB_CLICK
            trackEvent(FB_LOGIN, {
                work_platform_id: FACEBOOK_WORKPLATFORM_ID,
                work_platform_name: FACEBOOK_WORKPLATFORM_NAME,
                account_connection_type: "New_Connection",
            });
            redirectAuthFlow();
        }
    };

    const responseValues = {
        yes: "yes",
        no: "no",
    };

    // ques:number, response:"yes" | "no"
    const updateResponse = (ques, response) => {
        let eventName;
        switch (ques) {
            case 1:
                setTrackFirstQuestion(true);
                if (response === responseValues.yes) {
                    setFacebookQuestionnaire({
                        ...facebookQuestionnaire,
                        question1: true,
                    });
                    eventName = FB_QUESTION_YES;
                } else {
                    setFacebookQuestionnaire({
                        ...facebookQuestionnaire,
                        question1: false,
                    });
                    eventName = FB_QUESTION_NO;
                }
                break;
            default:
                console.error(`please pass the correct values in update response`);
        }

        // // Tracking event
        trackEvent(eventName, {
            work_platform_id: FACEBOOK_WORKPLATFORM_ID,
            work_platform_name: FACEBOOK_WORKPLATFORM_NAME,
            account_connection_type: "New_Connection",
        });
    };

    useEffect(() => {
        dispatchLoaderContext(true);
        if (!isEmpty(sdkInstance)) {
            getFlowURLFacebook(sdkInstance, sdkParams, allAccounts, dispatchAllAccContext).then((result) => {
                setBackURL(result);
                dispatchLoaderContext(false);
            });
        }
    }, [sdkInstance]);

    if (loader) {
        if (isConnectLoader && !isEmpty(loaderMsgConfig)) {
            return (
                <LoaderWithStatus
                    message={loaderMsgConfig.title.replace("{{PlatformName}}", FACEBOOK_WORKPLATFORM_NAME)}
                    platform={FACEBOOK_WORKPLATFORM_NAME}
                />
            );
        }
        return <Loader />;
    }

    return (
        <>
            <Background />
            <div className="row col-lg-3 col-md-10 col-sm-10 col-12 m-auto  white-screen">
                <ScreenNavbar
                    className=""
                    backURL={backURL}
                    exitReason={PHYLLO_EXIT_REASON_EXIT_FROM_PLATFORM + FACEBOOK_WORKPLATFORM_NAME}
                    screenName={FACEBOOK_SPECIAL_REQUIREMENTS}
                    workplatformDetails={{
                        workplatformId: FACEBOOK_WORKPLATFORM_ID,
                        workplatformName: FACEBOOK_WORKPLATFORM_NAME,
                    }}
                />
                <div className="scrollable-content-info-insta">
                    <div className="col-12  text-center ms-0 ">
                        <img src={fb_logo} alt="" />
                        <h2 className="heading-text-h2-insta">
                            {facebookLocalization[sdkParams.language]["Connecting your account requires a Facebook business page"]}
                        </h2>
                    </div>

                    <div className="main-div-insta mt-3">
                        <div>
                            <div className="question-info-container">
                                <p>{facebookLocalization[sdkParams.language]["Do you have a Facebook business page?"]}</p>
                                <div style={{ width: "18px" }}>
                                    <InfoIcon
                                        onClick={() => {
                                            window.open(`${FACEBOOK_HELP_GUIDE_URL}`, "_blank", "noopener,noreferrer");

                                            // Tracking event : INSTAGRAM_INFO_Q1
                                            trackEvent(FB_HOW_TO, {
                                                work_platform_id: FACEBOOK_WORKPLATFORM_ID,
                                                work_platform_name: FACEBOOK_WORKPLATFORM_NAME,
                                                account_connection_type: "New_Connection",
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="insta-auth-button-div">
                                <button
                                    className={
                                        facebookQuestionnaire.question1 ? "secondary-btn-insta-auth-enabled" : "secondary-btn-insta-auth-disabled"
                                    }
                                    onClick={() => updateResponse(1, responseValues.yes)}
                                >
                                    {facebookLocalization[sdkParams.language]["Yes"]}
                                </button>
                                <button
                                    className={
                                        trackFirstQuestion
                                            ? facebookQuestionnaire.question1
                                                ? "secondary-btn-insta-auth-disabled"
                                                : "secondary-btn-insta-auth-enabled"
                                            : "secondary-btn-insta-auth-disabled"
                                    }
                                    onClick={() => updateResponse(1, responseValues.no)}
                                >
                                    {facebookLocalization[sdkParams.language]["No"]}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-intro-div">
                    <div className="col-12 m-auto text-center mb-4">
                        <div className="intros-footer-txt col-12 m-auto mb-3 " style={{ color: "#DD5328" }}>
                            <p
                                style={{
                                    color: "#AD3737",
                                    height: "0px",
                                    fontWeight: "500",
                                    width: "85%",
                                    margin: "inherit",
                                    fontSize: "10px",
                                    paddingBottom: "4px",
                                }}
                            >
                                {trackFirstQuestion && !facebookQuestionnaire.question1
                                    ? facebookLocalization[sdkParams.language][
                                          "Facebook has the above requirement for a successful account connection"
                                      ]
                                    : ""}
                            </p>
                            <br />
                            <strong>
                                <a
                                    href={`${FACEBOOK_HELP_GUIDE_URL}`}
                                    target="_blank"
                                    style={{ fontSize: "11px" }}
                                    onClick={() => {
                                        // Tracking event : HELP_GUIDE_CLICK
                                        trackEvent(FB_HOW_TO, {
                                            work_platform_id: FACEBOOK_WORKPLATFORM_ID,
                                            work_platform_name: FACEBOOK_WORKPLATFORM_NAME,
                                            account_connection_type: "New_Connection",
                                        });
                                    }}
                                    rel="noreferrer"
                                >
                                    {facebookLocalization[sdkParams.language]["How to create a Facebook business page?"]}
                                </a>
                            </strong>
                        </div>

                        <img
                            src={login_button_fb}
                            alt=""
                            className="primary-btn"
                            style={facebookQuestionnaire.question1 ? { opacity: "100%" } : { opacity: "60%" }}
                            onClick={() => {
                                checkQuestionResponse();
                            }}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default FacebookAuthScreen;
