import { React, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  ACCOUNT_LINK_TRY_AGAIN,
  ERROR_INFO_SCREEN,
  CONNECTION_FAILURE_INFO,
} from "../../analytics/eventConstants";
import { trackEvent } from "../../analytics/sdkAnalytics";
import Background from "../../components/Background/Background";
import {
  CONNECTIONS,
  INFO_SCREEN,
  INSTA_AUTH1,
  LINKEDIN_PROFILE_AUTH,
  PLATFORM_SCREEN,
  SOMETHING_WENT_WRONG,
  TERMINATE_POPUP,
} from "../../constants/URLConstants";
import Loader from "../../components/Loader/Loader";
import {
  INSTA_WORKPLATFORM_ID,
  LINKEDIN_WORKPLATFORM_ID,
  PHYLLO_EXIT_REASON_EXIT_FROM_CONNECTION_FAILED,
  PHYLLO_ON_CONNECTION_FAILURE,
} from "../../constants/constants";
import { SDKInstanceState } from "../../context/SdkInstanceContext";

import { isEmpty } from "../../utils/sdk_utils";

function StatusInfoRequired(props) {
  const navigate = useNavigate();
  const params = useParams();
  const { authorizationid } = params;
  if (!authorizationid) {
    console.error("authorizationid is empty or invalid");
    navigate(`${SOMETHING_WENT_WRONG}`);
    return;
  }
  const { sdkInstance } = SDKInstanceState();

  async function handleInfoRequired() {
    const response = await sdkInstance.getAuthorizationInfo(authorizationid);
    if (isEmpty(response)) {
      navigate(`${SOMETHING_WENT_WRONG}`);
      return;
    }
    const workPlatformId = response?.work_platform?.id;

    if (workPlatformId === LINKEDIN_WORKPLATFORM_ID) {
      navigate(`/${LINKEDIN_PROFILE_AUTH}/${authorizationid}`, {
        state: {
          workPlatformId: LINKEDIN_WORKPLATFORM_ID,
        },
      });
    }
  }

  useEffect(() => {
    if (!isEmpty(sdkInstance)) handleInfoRequired();
  });

  return <Loader />;
}
export default StatusInfoRequired;
