import React from "react";
import "./IconListItem.scss";

export default function IconListItem({
  icon,
  heading,
  description,
  styleName,
  ...otherProps
}) {
  return (
    <div className={`icon-list-container${styleName || ""}`}>
      <span className="icon-item">{icon}</span>
      <div className="list-text">
        <h4>{heading}</h4>
        <p>{description}</p>
      </div>
    </div>
  );
}
