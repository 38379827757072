import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./BeeHiivAuth.scss";
import {
  CardContainer,
  LabelledInput,
  PrimaryButton,
  ScreenNavbar,
} from "../../components";
import {
  BEEHIIV_AUTH_SCREEN,
  BEEHIIV_LONG_LOADER_SCREEN,
} from "../../analytics/eventConstants";
import { WorkPlatformDetailsState } from "../../context/WorkPlatformDetailsContext";
import popupArrowIcon from "../../assets/images/popup_arrow.svg";
import playIcon from "../../assets/images/playIcon.svg";
import WarningIcon from "../../assets/images/warning_icon.svg";
import { SDKInstanceState } from "../../context/SdkInstanceContext";
import {
  BEEHIIV_SELECT_ACCOUNT,
  SOMETHING_WENT_WRONG,
  STATUS_CONNECTED,
  STATUS_FAILED,
} from "../../constants/URLConstants";
import { BEEHIIV_WORKPLATFORM_ID } from "../../constants/constants";
import { ConfigState } from "../../context/ConfigContext";
import BeeHiivLongLoader from "../BeeHiivLongLoader/BeeHiivLongLoader";

const SHORT_LOADER_TIMER = 3000;
let timer;
function BeeHiivAuth() {
  const videoPlayerContRef = useRef();
  const videoPlayer = useRef();
  const params = useParams();
  const location = useLocation();

  const { configuration, dispatchConfigContext } = ConfigState();
  const { sdkInstance, dispatchSdkInstContext } = SDKInstanceState();
  const { workplatformDetails, dispatchWorkPltfrmContext } =
    WorkPlatformDetailsState();
  const [platformDetail, setPlatformDetail] = useState(
    workplatformDetails[params.workplatformid]
  );
  const [apiKey, setAPIKey] = useState(null);
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState(null);
  // state for loader component
  const [isLoading, setIsLoading] = useState(false);
  const [useLongLoader, setUseLongLoader] = useState(false);

  // destructing config screen with custom name
  let shortLoaderStringConstants;
  let longLoaderStringConstant;
  if (Object.keys(configuration).length) {
    const { screens } = configuration;
    shortLoaderStringConstants = screens.short_loader_screen;
    longLoaderStringConstant = screens.long_loader_screen;
  }

  // function to make video player take full screen
  function enterFullscreen(videoElement) {
    // for chrome
    if (videoElement.requestFullscreen) {
      videoElement.requestFullscreen();
    }
    // for firefox
    else if (videoElement.mozRequestFullscreen) {
      videoElement.mozRequestFullscreen();
    }
    // for safari
    else if (videoElement.webkitRequestFullscreen) {
      videoElement.webkitRequestFullscreen();
    }
    // for Safari iPhone (where only the Video tag itself can be fullscreen)
    else if (videoElement.webkitEnterFullscreen) {
      videoElement.webkitEnterFullscreen();
    }
    // for mobile devices
    else if (videoElement.msRequestFullscreen) {
      videoElement.msRequestFullscreen();
    }
  }

  // func to close video player
  const closePlayer = () => {
    if (
      !document.fullscreenElement &&
      !document.webkitIsFullScreen &&
      !document.mozFullScreenElement
    ) {
      // Document has exited full screen mode
      videoPlayer.current.pause();
      videoPlayerContRef.current.classList.remove("show");
    }
  };

  // function to open video player
  const openPlayer = () => {
    videoPlayerContRef.current.classList.add("show");
    enterFullscreen(videoPlayer.current);
    // videoPlayer.current.play();
  };

  useEffect(() => {
    // event listeners for all browsers to listen for full screen change(events like esc, back on mobile, close full screen btn can trigger this)
    const prefixes = ["", "moz", "webkit", "ms"];
    prefixes.forEach(function (prefix) {
      document.addEventListener(`${prefix}fullscreenchange`, closePlayer);
    });
    videoPlayer?.current?.addEventListener("webkitendfullscreen", closePlayer);

    if (location?.state?.error) {
      setErrorMsg(location.state.error?.message);
    }

    return () => {
      prefixes.forEach(function (prefix) {
        document.removeEventListener(`${prefix}fullscreenchange`, closePlayer);
      });
      videoPlayer?.current?.removeEventListener(
        "webkitendfullscreen",
        closePlayer
      );
    };
  });
  /**
   * Executes onProceedHandler and retrieves the Beehiiv publications data.
   *
   * @return {Promise<void>} A Promise that resolves when the data is retrieved.
   */
  const onProceedHandler = async () => {
    // start loader component
    setIsLoading(true);
    // transistion timer from short to long loader
    timer = setTimeout(() => {
      if (setUseLongLoader) setUseLongLoader(true);
    }, SHORT_LOADER_TIMER);
    // post api key
    await postAPIKey();
    // clear timeout to avoid unwanted state changes
    clearTimeout(timer);
    // reset loader component states and move to Auth Comp
    if (setAPIKey) setAPIKey(null);
    if (setUseLongLoader) setUseLongLoader(false);
    if (setIsLoading) setIsLoading(false);
  };

  // post api key and get list of publications
  const postAPIKey = async () => {
    let response = await sdkInstance.getBeehiivPublications({
      workPlatformId: params.workplatformid,
      credentials: {
        api_key: apiKey,
      },
    });

    let status;
    if (response) {
      status = response?.status;
      response = await response.json();
    }

    if (status === 200) {
      // if only 1 publication connect it by default and move to account connect screen
      if (response.data.length === 1) {
        // connect the one and only acc
        let connectAccRes = await sdkInstance.postConnectWithPlatform({
          workPlatformId: params.workplatformid,
          credentials: {
            api_key: apiKey,
          },
          platform_publication_ids: [response.data[0].id],
          authorization_id: response?.authorization_id,
        });

        // success
        if (connectAccRes?.status === 200) {
          connectAccRes = await connectAccRes.json();
          // get acc ids
          const acc_ids = connectAccRes.data.map((acc) => {
            return acc.id;
          });
          // navigate to account connect
          navigate(`/${STATUS_CONNECTED}/${acc_ids}`);
          return;
        } else {
          navigate(`/${STATUS_FAILED}/${response?.authorization_id}`);
          return;
        }
      }

      // if more than 1 publication
      navigate(`/${BEEHIIV_SELECT_ACCOUNT}`, {
        state: {
          publications: response?.data,
          apiKey,
          workPlatformId: params?.workplatformid,
          authorization_id: response?.authorization_id,
        },
      });
    }
    // if api key is invalid
    else if (
      status === 400 &&
      response?.error?.error_code === "invalid_credentials"
    ) {
      setErrorMsg("This is an invalid API key, please create a new key.");
    }
    // for server and internet errors
    else {
      navigate(`/${SOMETHING_WENT_WRONG}`);
    }
  };

  return isLoading ? (
    <BeeHiivLongLoader
      useLongLoader={useLongLoader}
      shortLoaderStringConstants={shortLoaderStringConstants}
      longLoaderStringConstant={longLoaderStringConstant}
      logo_url={platformDetail?.logo_url}
      long_loader_texts={platformDetail?.sdk_loader_config?.long_loader_texts}
      screenName={BEEHIIV_LONG_LOADER_SCREEN}
      workPlatformId={platformDetail?.id}
      work_platform_name={platformDetail?.name}
    />
  ) : (
    <div className="page-background beehiiv-auth-container">
      <CardContainer>
        <ScreenNavbar
          backURL={-1}
          screenName={BEEHIIV_AUTH_SCREEN}
          workplatformDetails={{
            workplatformId: platformDetail?.id,
            workplatformName: platformDetail?.name,
          }}
        />
        <div className="platform-icon">
          <img src={platformDetail?.logo_url} />
        </div>
        <div className="platform-title">
          <p>Connect your Beehiiv account with an API key</p>
        </div>
        <div className="platform-body">
          <ol className="platform-instructions">
            <li>
              Go to{" "}
              <a
                href="https://app.beehiiv.com/settings/integrations"
                target="_blank"
                rel="noreferrer"
              >
                Beehiiv settings
                <img src={popupArrowIcon} alt="pop-up-arrow" />
              </a>
            </li>
            <li>
              Create an API key. Don’t forget to copy the key.{" "}
              <span
                onClick={(ev) => {
                  openPlayer();
                }}
                className="instructions-highlight-text"
              >
                Learn how
                <img src={playIcon} alt="icon-to-open-video" />
              </span>
              {/* hidden by default opens only in full screen */}
              <div id="video-container" ref={videoPlayerContRef}>
                <video controls ref={videoPlayer}>
                  <source
                    src="https://cdn.getphyllo.com/guides/videos/beehiiv/find_api_key.mp4"
                    type="video/mp4"
                  />
                  <track
                    src="captions.vtt"
                    kind="captions"
                    label="English"
                    default
                  />
                </video>
                <span
                  id="closeButton"
                  onClick={() => {
                    closePlayer();
                  }}
                >
                  &times;
                </span>
              </div>
            </li>

            <li>Copy the API key and paste it below.</li>
          </ol>
          <div className="input-container">
            <LabelledInput
              placeholder="Paste API key here"
              containerProps={{ style: { marginBottom: "14px" } }}
              name="username"
              errorMsg={
                errorMsg && !apiKey ? (
                  <>
                    <img
                      src={WarningIcon}
                      alt="warning-icon"
                      style={{ verticalAlign: "sub", marginRight: "4px" }}
                    />
                    {errorMsg}
                  </>
                ) : null
              }
              style={
                errorMsg && !apiKey
                  ? {
                      border: "1.5px solid #AD3737",
                      marginBottom: "8px",
                      textOverflow: "ellipsis",
                      padding: "16px",
                    }
                  : { textOverflow: "ellipsis", padding: "16px" }
              }
              onChange={(ev) => {
                setAPIKey(ev.target.value);
              }}
            />
          </div>
        </div>

        <PrimaryButton
          onClick={onProceedHandler}
          style={apiKey ? {} : { opacity: 0.5 }}
          disabled={!apiKey}
          className="primary-btn"
        >
          Proceed
        </PrimaryButton>
      </CardContainer>
    </div>
  );
}

export default BeeHiivAuth;
