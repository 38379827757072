import { CONNECTIONS, INSTA_AUTH1, PLATFORM_SCREEN, SOMETHING_WENT_WRONG } from "../constants/URLConstants";
import { FACEBOOK_WORKPLATFORM_ID, INSTA_WORKPLATFORM_ID, PHYLLO_CONNECTED_ACCOUNT, PHYLLO_SESSION_EXPIRED_ACCOUNT } from "../constants/constants";
import { AllAccountsActions, LoadingStates } from "./Reducers/Reducers";
import { isEmpty } from "./sdk_utils";

export const getFlowURL = (sdkParams) => {
    const { flow, userId, appName, workPlatformId, token, version, mobile, env, redirectURL, singleAccount, mobileVersion, sdk } = sdkParams;

    const queryParams = `userId=${userId}&token=${token}&env=${env}&redirectURL=${redirectURL}&appName=${appName}&workPlatformId=${workPlatformId}&mobile=${mobile}&mobileVersion=${mobileVersion}&sdk=${sdk}&singleAccount=${singleAccount}&version=${version}`;
    let url = "";
    if (flow && flow !== "null") {
        url = `/introscreen/${flow}/?${queryParams}`;
    } else {
        url = `/introscreen/main/?${queryParams}`;
    }
    return url;
};

export const getFlowURLInsta = async (sdkInstance, sdkParams, allAccounts, dispatchAllAccContext) => {
    let connectedPlatformsDetails;
    if (allAccounts.isLoading === LoadingStates.completed && !isEmpty(allAccounts.data)) {
        connectedPlatformsDetails = allAccounts.data;
    } else {
        dispatchAllAccContext({ type: AllAccountsActions.setLoadingTrue });
        connectedPlatformsDetails = await sdkInstance.getAllAccounts();
        dispatchAllAccContext({
            type: AllAccountsActions.addAllAccounts,
            payload: connectedPlatformsDetails,
        });
    }
    if (Object.keys(connectedPlatformsDetails).length === 0) return `/${SOMETHING_WENT_WRONG}`;
    const instaConnectedAccounts = connectedPlatformsDetails.data.filter((item) => item.work_platform.id === INSTA_WORKPLATFORM_ID);
    if (instaConnectedAccounts.length === 0) {
        if (sdkParams.flow !== "null" && sdkParams.flow !== undefined) return getFlowURL(sdkParams);
        return `/${PLATFORM_SCREEN}/`;
    }
    if (
        instaConnectedAccounts.filter((item) => item.status === PHYLLO_CONNECTED_ACCOUNT).length > 0 ||
        connectedPlatformsDetails.data.filter((item) => item.status === PHYLLO_SESSION_EXPIRED_ACCOUNT).length > 0
    )
        return `/${CONNECTIONS}/${INSTA_WORKPLATFORM_ID}`;

    if (sdkParams.flow !== "null" && sdkParams.flow !== undefined) return getFlowURL(sdkParams);
    return `/${PLATFORM_SCREEN}/`;
};

export const getFlowURLFacebook = async (sdkInstance, sdkParams, allAccounts, dispatchAllAccContext) => {
    let connectedPlatformsDetails;
    if (allAccounts.isLoading === LoadingStates.completed && !isEmpty(allAccounts.data)) {
        connectedPlatformsDetails = allAccounts.data;
    } else {
        dispatchAllAccContext({ type: AllAccountsActions.setLoadingTrue });
        connectedPlatformsDetails = await sdkInstance.getAllAccounts();
        dispatchAllAccContext({
            type: AllAccountsActions.addAllAccounts,
            payload: connectedPlatformsDetails,
        });
    }
    if (Object.keys(connectedPlatformsDetails).length === 0) return `/${SOMETHING_WENT_WRONG}`;
    const facebookConnectedAccounts = connectedPlatformsDetails.data.filter((item) => item.work_platform.id === FACEBOOK_WORKPLATFORM_ID);
    if (facebookConnectedAccounts.length === 0) {
        if (sdkParams.flow !== "null" && sdkParams.flow !== undefined) return getFlowURL(sdkParams);
        return `/${PLATFORM_SCREEN}/`;
    }
    if (
        facebookConnectedAccounts.filter((item) => item.status === PHYLLO_CONNECTED_ACCOUNT).length > 0 ||
        connectedPlatformsDetails.data.filter((item) => item.status === PHYLLO_SESSION_EXPIRED_ACCOUNT).length > 0
    )
        return `/${CONNECTIONS}/${FACEBOOK_WORKPLATFORM_ID}`;

    if (sdkParams.flow !== "null" && sdkParams.flow !== undefined) return getFlowURL(sdkParams);
    return `/${PLATFORM_SCREEN}/`;
};
