import React, { useEffect, useReducer, useState } from "react";
import { ConfigState } from "../../context/ConfigContext";
import { SDKInstanceState } from "../../context/SdkInstanceContext";
import { SDKParamsState } from "../../context/SdkParmasContext";
import { WorkPlatformDetailsState } from "../../context/WorkPlatformDetailsContext";
import {
  ConfigActions,
  SDKInstanceCtxActions,
} from "../../utils/Reducers/Reducers";
import { getSDKInstance, isEmpty } from "../../utils/sdk_utils";
import URL from "../URL/URL";

function Main() {
  const { sdkParams } = SDKParamsState();
  const { workplatformDetails } = WorkPlatformDetailsState();
  const { sdkInstance, dispatchSdkInstContext } = SDKInstanceState();
  const { configuration, dispatchConfigContext } = ConfigState();

  const setAccentColor = (color) => {
    document.documentElement.style.setProperty("--accentcolor", color);
  };

  const getConfiguration = async () => {
    try {
      const sdkInstanceValue = await getSDKInstance(sdkParams);
      const configurationValue = await sdkInstanceValue.getConfiguration();
      dispatchSdkInstContext({
        type: SDKInstanceCtxActions.setData,
        payload: sdkInstanceValue,
      });
      dispatchConfigContext({
        type: ConfigActions.setData,
        payload: configurationValue,
      });
      setAccentColor(configurationValue?.sdk_colors?.accent_color);
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  useEffect(() => {
    if ((isEmpty(configuration) || isEmpty(sdkInstance)) && !isEmpty(sdkParams))
      getConfiguration();
  }, [sdkParams, workplatformDetails]);

  return <URL />;
}

export default React.memo(Main);
