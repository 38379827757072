export const somethingWentWrongInfoTranslations = {
    en: {
        "Something went wrong": "Something went wrong",
        "We are checking it.": "We are checking it.",
        "Try again": "Try again",
    },
    id: {
        "Something went wrong": "Ada yang salah",
        "We are checking it.": "Kami sedang memeriksanya.",
        "Try again": "Coba Lagi",
    },
    ja: {
        "Something went wrong": "何か問題が発生しました",
        "We are checking it.": "確認中です。",
        "Try again": "もう一度やり直してください",
    },
    cn: {
        "Something went wrong": "出了些问题",
        "We are checking it.": "我们正在检查。",
        "Try again": "再试一次",
    },
    zh: {
        "Something went wrong": "出了些问题",
        "We are checking it.": "我们正在检查。",
        "Try again": "再试一次",
    },
    fr: {
        "Something went wrong": "Quelque chose s'est mal passé",
        "We are checking it.": "Nous le vérifions.",
        "Try again": "Essayer à nouveau",
    },
    "zh-tw": {
        "Something went wrong": "出了點問題",
        "We are checking it.": "我們正在檢查它",
        "Try again": "再試一次",
    },
    es: {
        "Something went wrong": "Algo salió mal",
        "We are checking it.": "Lo estamos comprobando.",
        "Try again": "Intentar otra vez",
    },
    pt: {
        "Something went wrong": "Something went wrong",
        "We are checking it.": "Estamos verificando isso.",
        "Try again": "Tente novamente",
    },
};
