import React from "react";
// import PropTypes from 'prop-types';
import CircularProgress from "@material-ui/core/CircularProgress";
import Background from "../Background/Background";
import infoicon from "../../assets/images/Screennavbar/info-icon.svg";
import closeicon from "../../assets/images/Screennavbar/close-icon.svg";
import { LoaderWithStatusState } from "../../context/LoaderWithStatusContext";
import { trackEvent } from "../../analytics/sdkAnalytics";
import { SDKParamsState } from "../../context/SdkParmasContext";
import { SDK_OAUTH_LOADER_MESSAGE_LINK_TEXT, SDK_OAUTH_LOADER_MESSAGE_TITLE } from "../../constants/constants";

function LoaderWithStatus({ message, platform, showPopupMsg = true }) {
    const { windowPopup } = LoaderWithStatusState();
    const { sdkParams } = SDKParamsState();
    function showPopup() {
        windowPopup.focus();
        trackEvent("POPOP_FOCUS_CLICK", {});
    }
    return (
        <>
            <Background />
            <div className="row col-lg-3 col-md-10 col-sm-10 col-12 m-auto  white-screen">
                <div className="navbar">
                    <div className="nav-icon col-12">
                        <div className="nav-icon">
                            <div className="right-icon">
                                <img src={infoicon} className="info-icon" />
                                <img src={closeicon} className="" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="loader">
                    <CircularProgress disableShrink />
                    <h2 className="heading-text mt-5"> {message}</h2>
                    {windowPopup && showPopupMsg && (
                        <p className="helpText">
                            {SDK_OAUTH_LOADER_MESSAGE_TITLE[sdkParams.language].replace("{{platform}}", platform)}
                            <u onClick={showPopup}>{SDK_OAUTH_LOADER_MESSAGE_LINK_TEXT[sdkParams.language]}</u>
                        </p>
                    )}
                </div>
            </div>
        </>
    );
}

export default LoaderWithStatus;
