import React from "react";
import "./TinyDescription.scss";

export default function TinyDescription({
  children,
  className = "",
  ...otherProps
}) {
  return (
    <div className={`description-container ${className}`} {...otherProps}>
      {children}
    </div>
  );
}
