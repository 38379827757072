import React from "react";
import { useNavigate } from "react-router-dom";
import Background from "../../components/Background/Background";
import ScreenNavbar from "../../components/ScreenNavbar/ScreenNavbar";
import interneterroricon from "../../assets/images/interneterror-icon.svg";
import "./SomethingWentWrong.scss";
import { SOMETHING_WENT_WRONG } from "../../constants/URLConstants";
import { somethingWentWrongInfoTranslations } from "./translations";
import { SDKParamsState } from "../../context/SdkParmasContext";

function SomethingWentWrong() {
    const navigate = useNavigate();
    const { sdkParams } = SDKParamsState();
    return (
        <>
            <Background />
            <div className="row col-lg-3 col-md-10 col-sm-10 col-12 m-auto  white-screen">
                <ScreenNavbar exitReason="EXIT_FROM_APP" screenName={SOMETHING_WENT_WRONG} />
                <div className="row mb-5 col-11 text-center stausfaildicon" style={{ margin: "auto" }}>
                    <img src={interneterroricon} alt="" />
                    <h2 className="heading-text mt-2">{somethingWentWrongInfoTranslations[sdkParams.language]["Something went wrong"]}</h2>
                    <p className="mt-3 failed-descript}ion-text">{somethingWentWrongInfoTranslations[sdkParams.language]["We are checking it."]}</p>
                </div>

                <div className="row mb-5  col-12 m-auto text-center">
                    <button
                        className="primary-btn "
                        onClick={() => {
                            navigate(-1); // go back
                        }}
                    >
                        {somethingWentWrongInfoTranslations[sdkParams.language]["Try again"]}
                    </button>
                </div>
            </div>
        </>
    );
}
export default SomethingWentWrong;
