import React from "react";
import "./LabelledInput.scss";

export default function LabelledInput({
  label,
  errorMsg = "",
  containerProps = {},
  ...inputProps
}) {
  return (
    <div className="labelled-input" {...containerProps}>
      <label htmlFor="labeled-input-field">{label}</label>
      <div className="input-field">
        <input
          {...inputProps}
          name={inputProps.name || label.toLowerCase()}
          className={errorMsg ? "error-input" : ""}
          id="labeled-input-field"
        />
        <div className="input-field-right-icon">{inputProps.icon}</div>
      </div>
      {errorMsg && <div className="error-message">{errorMsg}</div>}
    </div>
  );
}
