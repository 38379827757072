import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import queryString from "query-string";
import LogRocket from "logrocket";
import {
    BACK_ARROW_CLICK,
    CONNECT_CONSENT_ACCEPT,
    CONNECT_INITIATED,
    CONNECT_PRIVACY_POLICY_CLICK,
    CONSENT_ACCEPT,
    CONSENT_VIEW,
    CONSENT,
    PRIVACY_POLICY_CLICK,
    CONNECT_MODAL_CLOSE,
    CONNECT_INITIALIZE_METHOD,
    BACK_BUTTON_CLICK,
} from "../../analytics/eventConstants";
import { logUser, trackEvent, updateAnalytics } from "../../analytics/sdkAnalytics";
import { CONNECTIONS, PLATFORM_SCREEN, SOMETHING_WENT_WRONG } from "../../constants/URLConstants";
import { FACEBOOK_WORKPLATFORM_ID, INSTA_WORKPLATFORM_ID, PHYLLO_EXIT_REASON_BACK_PRESSED, PHYLLO_ON_EXIT_KEY } from "../../constants/constants";
import { AllAccountsState } from "../../context/AllAccountsContext";
import { ConfigState } from "../../context/ConfigContext";
import { SDKInstanceState } from "../../context/SdkInstanceContext";
import { SDKParamsState } from "../../context/SdkParmasContext";
import { WorkPlatformDetailsState } from "../../context/WorkPlatformDetailsContext";
import { getRedirectParams } from "../../utils/getRedirectParams";
import { instaFlowRedirection } from "../../utils/InstaFlowRedirection";
import backicon from "../../assets/images/NavbarIcons/back-arrow-icon.svg";
import { AllAccountsActions, LoadingStates, SDKParamsActions, WorkPlatformDetailsActions } from "../../utils/Reducers/Reducers";
import logo_placeholder from "../../assets/images/left_placeholder.svg";
import user_placeholder from "../../assets/images/right_placeholder.svg";
import placeholder_profilepic from "../../assets/images/placeholder_profilepic.svg";
import { ReactComponent as Mobile } from "../../assets/images/mobile-illustration.svg";
import { ReactComponent as DataSafe } from "../../assets/images/intro_lock_icon.svg";
import { ReactComponent as DataConsent } from "../../assets/images/intro_heart_icon.svg";
import { ReactComponent as DataSecure } from "../../assets/images/intro_shield_icon.svg";
import "./IntroScreen.scss";
import LoaderWithStatus from "../../components/LoaderWithStatus/LoaderWithStatus";
import Loader from "../../components/Loader/Loader";
import Background from "../../components/Background/Background";
import { isEmpty } from "../../utils/sdk_utils";
import login_button_fb from "../../assets/images/login_button_fb.svg";
import instagif from "../../assets/images/gif.gif";
import { LoaderState } from "../../context/LoaderContext";
import { LoaderWithStatusState } from "../../context/LoaderWithStatusContext";
import { facebookFlowRedirection } from "../../utils/FacebookFlowRedirection";

function IntroScreen() {
    const navigate = useNavigate();
    const [introDetails, setIntroDetails] = useState({});
    const { isConnectLoader } = LoaderWithStatusState();
    const [platformToConnect, setPlatformToConnect] = useState("");
    const [isLogoPresent, setIsLogoPresent] = useState(false);
    const [params, setParams] = useState({});
    const [isWorkplatformPresent, setIsWorkplatformPresent] = useState(false);
    const [workPlatform, setWorkPlatform] = useState({});
    const { sdkInstance, dispatchSdkInstContext } = SDKInstanceState();
    const { configuration, dispatchConfigContext } = ConfigState();
    const { sdkParams, dispatchSdkParamsContext } = SDKParamsState();
    const { workplatformDetails, dispatchWorkPltfrmContext } = WorkPlatformDetailsState();
    const { allAccounts, dispatchAllAccContext } = AllAccountsState();
    const { loader, dispatchLoaderContext } = LoaderState();
    const continueBtnOnClick = async (e) => {
        // const workPlatformId = sdkParams['flow'];
        const workPlatformId = sdkParams.flow;

        if (workPlatformId === "null") navigate(`/${PLATFORM_SCREEN}/`);
        else if (workPlatformId !== "null") {
            dispatchLoaderContext(true);
            // Redirecting to Instagram specific flow
            if (workPlatformId === INSTA_WORKPLATFORM_ID) {
                instaFlowRedirection(sdkInstance, allAccounts, dispatchAllAccContext).then((response) => navigate(response));
            } else if (workPlatformId === FACEBOOK_WORKPLATFORM_ID) {
                facebookFlowRedirection(sdkInstance, allAccounts, dispatchAllAccContext).then((response) => navigate(response));
            } else navigate(`/${CONNECTIONS}/${params.workPlatformId}`);
        } else navigate(`/${PLATFORM_SCREEN}/`);

        // Tracking event : CONSENT_ACCEPT
        trackEvent(CONSENT_ACCEPT);
    };

    const cacheImages = async (images) => {
        const promises = await images.map((item) => {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.src = item;
                img.onload = resolve();
                img.onerror = reject();
            });
        });
        await Promise.all(promises);
    };
    const populateWorkplatformContext = async (params) => {
        const workplatformDetailsObj = {};
        if (params.workPlatformId !== "null" && params.workPlatformId !== "" && params.workPlatformId !== undefined) {
            const workplatformDetails = await sdkInstance.getWorkPlatform(params.workPlatformId);
            workplatformDetailsObj[params.workPlatformId] = workplatformDetails;
        } else {
            const workplatformDetails = await sdkInstance.getAllWorkPlatforms();
            workplatformDetails.data.forEach((element) => {
                workplatformDetailsObj[element.id] = element;
            });
        }
        // Caching workplatform Icons
        // cacheIcons(workplatformDetailsObj)
        // usePreloadImages(workplatformDetailsObj)

        dispatchWorkPltfrmContext({
            type: WorkPlatformDetailsActions.addWorkPltrm,
            payload: workplatformDetailsObj,
        });
    };
    const populateDetails = async (params) => {
        setIntroDetails(configuration.screens.intro_screen);
        if (configuration.tenant_app_details.tenant_app_logo !== "") setIsLogoPresent(true);
        if (params.workPlatformId !== "null" && params.workPlatformId !== "" && params.workPlatformId !== undefined) {
            setIsWorkplatformPresent(true);
            const workspace = workplatformDetails[params.workPlatformId];
            if (workspace) {
                setWorkPlatform(workspace);
                setPlatformToConnect(workspace.name);
                dispatchLoaderContext(false);
            }
        } else {
            dispatchLoaderContext(false);
        }

        // get all accounts in background
        if (allAccounts.isLoading === LoadingStates.pending || isEmpty(allAccounts.data)) {
            dispatchAllAccContext({ type: AllAccountsActions.setLoadingTrue });
            const data = await sdkInstance.getAllAccounts();
            dispatchAllAccContext({
                type: AllAccountsActions.addAllAccounts,
                payload: data,
            });
        }

        // prefetch images
        const images = [];
        for (const item of Object.values(workplatformDetails)) {
            images.push(item.logo_url);
            if (!isEmpty(item.login_button)) {
                images.push(item.login_button.image_url);
            }
        }
        images.push(logo_placeholder);
        images.push(instagif);
        images.push(logo_placeholder);
        images.push(login_button_fb);
        if (isLogoPresent) images.push(configuration.tenant_app_details.tenant_app_logo);
        cacheImages(images);
    };

    const goToRedirectUrl = (e) => {
        // const redirectURL = sdkParams['redirectURL'];
        // if(sdkParams['redirectURL'] !== 'null') getRedirectParams(redirectURL,PHYLLO_EXIT_REASON_BACK_PRESSED);

        const { redirectURL } = sdkParams;
        if (sdkParams.redirectURL !== "null") {
            getRedirectParams(redirectURL, PHYLLO_EXIT_REASON_BACK_PRESSED, sdkParams.userId);
        } else {
            // Sending onExit callback
            window.parent.postMessage(
                {
                    [PHYLLO_ON_EXIT_KEY]: {
                        reason: "BACK_PRESSED",
                        userId: sdkParams.userId,
                    },
                },
                "*"
            );
        }
        // Tracking event : BACK_ARROW_CLICK
        trackEvent(CONNECT_MODAL_CLOSE, {
            reason: "USER_EXIT",
        });
    };

    useEffect(async () => {
        // ----------------ANALYTICS------------------//
        // Extracting super properties

        const config = queryString.parse(window.location.search);
        await updateAnalytics(config);

        // await updateAnalytics(config);
        // if (isEmpty(superProperties)) {
        //     superProperties = await updateAnalytics(config);
        // }

        // Logging user
        logUser(config.userId, {
            userId: sdkParams.userId,
            appName: sdkParams.appName,
        });

        // Tracking event : initiate
        trackEvent(CONSENT_VIEW);

        // Tracking event : CONNECT_INITIALIZE_METHOD
        trackEvent(CONNECT_INITIALIZE_METHOD);
    }, []);

    useEffect(() => {
        // TODO: Get the userid, token etc from the
        const params = queryString.parse(window.location.search);
        if (!params.env || !params.redirectURL || !params.appName || !params.workPlatformId) navigate(`/${SOMETHING_WENT_WRONG}`);
        else {
            // Only dispatching when sdkParams is empty for avoiding rerender
            if (isEmpty(sdkParams))
                dispatchSdkParamsContext({
                    type: SDKParamsActions.addParams,
                    payload: params,
                });
            setParams(params);
        }

        if (!isEmpty(sdkParams) && !isEmpty(configuration)) {
            if (isEmpty(workplatformDetails)) populateWorkplatformContext(params);
            populateDetails(params);

            LogRocket.identify(params.userId, {
                env: params.env,
                userId: params.userId,
                workplatformId: params.workPlatformId,
                workplatformName: platformToConnect,
                redirectURL: params.redirectURL,
                appName: params.appName,
                singleAccount: params.singleAccount,
                mobile: params.mobile,
                mobileVersion: params.mobileVersion,
                sdk: params.sdk,
            });
        }
    }, [configuration, sdkInstance, workplatformDetails]);

    return (
        <>
            {loader ? isConnectLoader ? <LoaderWithStatus work_platform={platformToConnect} /> : <Loader /> : null}
            {!loader && introDetails && introDetails.policy_button_title ? (
                <>
                    <Background />
                    <div className="row col-lg-3 col-md-10 col-sm-10 col-12 m-auto  white-screen">
                        <div className="header-intro-div">
                            <div className="navbar header-intro-div" style={{ zIndex: "2" }}>
                                <div className="nav-icon col-10">
                                    <div className="nav-icon" style={{ margin: "16px 0px" }}>
                                        <img
                                            src={backicon}
                                            style={{ zIndex: "3" }}
                                            onClick={() => {
                                                // Tracking event : BACK_BUTTON_CLICK
                                                trackEvent(BACK_BUTTON_CLICK, {
                                                    screen_name: CONSENT,
                                                });
                                                // setTimeout is to ensure that the above events get tracked before SDK modal gets removed from the memory
                                                setTimeout(() => goToRedirectUrl(), 50);
                                            }}
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="scrollable-content-info">
                            <div className="row col-12 ms-0 mt-4" style={{ zIndex: "1", paddingTop: "1px" }}>
                                <div className="mobile-illustration">
                                    {isLogoPresent ? (
                                        <img src={configuration.tenant_app_details.tenant_app_logo} alt="" className="client_logo_placeholder" />
                                    ) : (
                                        <img src={logo_placeholder} className="default_logo_placeholder" alt="" />
                                    )}
                                    <Mobile className="svg-mobile-illustration" />
                                    {isWorkplatformPresent ? (
                                        <img src={workPlatform.logo_url} alt="" className="workplatform_logo_placeholder" />
                                    ) : (
                                        <img src={user_placeholder} className="user_logo_placeholder" alt="" />
                                    )}
                                </div>
                            </div>

                            <div className="demo_div">
                                <h2 className="heading-text " style={{ width: "90%", marginBottom: "1.25rem" }}>
                                    {introDetails.title ? introDetails.title.replace("{{DeveloperName}}", sdkParams.appName) : null}
                                </h2>
                                <div className="instructions-text">
                                    {introDetails.terms.map((item, key) => (
                                        <div className="row  col-11 m-auto" key={key}>
                                            <div className="Icongroup">
                                                {/* <img src={item.logo_url} className="intro-icon" /> */}
                                                {key ? (
                                                    key === 1 ? (
                                                        <DataSafe className="intro-icon" />
                                                    ) : (
                                                        <DataSecure className="intro-icon" />
                                                    )
                                                ) : (
                                                    <DataConsent className="intro-icon" />
                                                )}
                                                {/* <DataSafe className='intro-icon' /> */}
                                                <div className="Icon-heading">
                                                    <h4>{item.title}</h4>
                                                    <p>{item.description}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="footer-intro-div">
                            <div className="row col-12 m-auto text-center ">
                                <p className="intros-footer-txt col-12 m-auto mb-3 ">
                                    {introDetails.policy_text}
                                    <br />
                                    <strong>
                                        {introDetails.policy_link_url ? (
                                            <a
                                                href={introDetails.policy_link_url}
                                                target="_blank"
                                                onClick={() =>
                                                    trackEvent(PRIVACY_POLICY_CLICK, {
                                                        screen_name: CONSENT,
                                                    })
                                                }
                                                rel="noreferrer"
                                            >
                                                {introDetails.policy_link_title}
                                            </a>
                                        ) : null}
                                    </strong>
                                </p>

                                {params.flow !== "info" ? (
                                    <button
                                        className="primary-btn mb-4"
                                        onClick={(e) => {
                                            continueBtnOnClick(e);
                                        }}
                                    >
                                        {introDetails.policy_button_title}
                                    </button>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </>
            ) : null}
        </>
    );
}

export default IntroScreen;
