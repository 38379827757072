import React from "react";
import "./CardBody.scss";

export default function CardBody({ children, className = "", ...otherProps }) {
  return (
    <div className={`mt-35 card-body-container ${className}`} {...otherProps}>
      {children}
    </div>
  );
}
