import { createContext, useContext, useReducer } from "react";
import { ConfigReducer, LoadingStates } from "../utils/Reducers/Reducers";

export const Configuration = createContext();

function ConfigContext({ children }) {
    const [configuration, dispatchConfigContext] = useReducer(ConfigReducer, {
        isLoading: LoadingStates.pending,
        data: {},
    });

    const val = {
        loading: configuration.isLoading,
        configuration: configuration.data,
        dispatchConfigContext,
    };

    return <Configuration.Provider value={val}>{children}</Configuration.Provider>;
}

export const ConfigState = () => useContext(Configuration);

export default ConfigContext;
