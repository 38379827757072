import { createContext, useContext, useReducer } from "react";
import { LoadingStates, SDKParamsReducer } from "../utils/Reducers/Reducers";

export const SDKParams = createContext();

function SDKParamsContext({ children }) {
    const [sdkParams, dispatchSdkParamsContext] = useReducer(SDKParamsReducer, {
        isLoading: LoadingStates.pending,
        data: {},
    });

    const val = {
        loading: sdkParams.isLoading,
        sdkParams: sdkParams.data,
        dispatchSdkParamsContext,
    };

    return <SDKParams.Provider value={val}>{children}</SDKParams.Provider>;
}

export const SDKParamsState = () => useContext(SDKParams);

export default SDKParamsContext;
