import React from "react";
import "./PrimaryButton.scss";

export default function PrimaryButton({ children, ...otherProps }) {
  return (
    <button
      {...otherProps}
      className="primary-button"
      // onClick={() => {
      //   redirectAuthFlow();
      // }}
    >
      {children}
      {/* <img src={logoImagePath} className="img-btn" alt="" loading="lazy" /> */}
    </button>
  );
}
