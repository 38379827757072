import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import { Carousel } from "react-bootstrap";
import "./BeeHiivLongLoader.scss";
import {
  CardBody,
  CardContainer,
  CardTitle,
  ScreenNavbar,
} from "../../components";
import { BEEHIIV_LONG_LOADER_SCREEN } from "../../analytics/eventConstants";
import { WorkPlatformDetailsState } from "../../context/WorkPlatformDetailsContext";
import { ConfigState } from "../../context/ConfigContext";
import { ReactComponent as PhylloIcon } from "../../assets/images/phyllo_icon.svg";
import { ReactComponent as SpiralConnection } from "../../assets/images/spiral.svg";
import LoaderWithStatus from "../../components/LoaderWithStatus/LoaderWithStatus";
import LongLoader from "../../components/LongLoader/LongLoader";
import { SDKInstanceState } from "../../context/SdkInstanceContext";
import {
  BEEHIIV_AUTH,
  BEEHIIV_SELECT_ACCOUNT,
  SOMETHING_WENT_WRONG,
  STATUS_CONNECTED,
} from "../../constants/URLConstants";

// timer to transition from short loader to long loader
const SHORT_LOADER_TIMER = 3000;
function BeeHiivLongLoader({
  useLongLoader,
  shortLoaderStringConstants,
  longLoaderStringConstant,
  logo_url,
  long_loader_texts,
  screenName,
  workPlatformId,
  work_platform_name,
}) {
  return (
    <div className="page-background">
      {!useLongLoader ? (
        <LoaderWithStatus
          message={shortLoaderStringConstants.title.replace(
            "{{PlatformName}}",
            work_platform_name
          )}
          showPopupMsg={false}
        />
      ) : (
        <LongLoader
          longLoaderStringConstant={longLoaderStringConstant}
          work_platform_name={work_platform_name}
          logo_url={logo_url}
          long_loader_texts={long_loader_texts}
          screenName={screenName}
          workPlatformId={workPlatformId}
        />
      )}
    </div>
  );
}

export default BeeHiivLongLoader;
