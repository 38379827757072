import { debounce } from "@material-ui/core";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BACK_BUTTON_CLICK } from "../../analytics/eventConstants";
import { trackEvent } from "../../analytics/sdkAnalytics";
import backIcon from "../../assets/images/NavbarIcons/back-arrow-icon.svg";
import closeIcon from "../../assets/images/NavbarIcons/close-icon.svg";
import infoIcon from "../../assets/images/NavbarIcons/information-icon.svg";
import { INFO_SCREEN, TERMINATE_POPUP } from "../../constants/URLConstants";
import { LoaderState } from "../../context/LoaderContext";
import "./ScreenNavbar.scss";

function ScreenNavbar(props) {
  const { dispatchLoaderContext } = LoaderState();  
  const navigate = useNavigate();
    const backBtn = () => {
      dispatchLoaderContext(true);
        if (props.backURL) navigate(props.backURL);
        else navigate(-1);
        // Tracking event : BACK_BUTTON_CLICK
        trackEvent(BACK_BUTTON_CLICK, {
            screen_name: props.screenName,
            work_platform_id: props?.workplatformDetails?.workplatformId,
            work_platform_name: props?.workplatformDetails?.workplatformName,
        });
    };

    // const terminateFlow = async () => {
    //   const redirectURL = await localStorage.getItem("redirectURL");
    //   window.location.replace(redirectURL);
    // };

    return (
        <div className="navbar-container">
            {props.noBackButton ? <span /> : <img src={backIcon} alt="back_icon" onClick={() => backBtn()} className="navbar-left-icons" />}

            {!props.noRenderRightPanel ? (
                <div className="navbar-right-icons">
                    <img
                        src={infoIcon}
                        className="info-icon"
                        alt="info_icon"
                        onClick={() => {
                          dispatchLoaderContext(true);
                          navigate(`/${INFO_SCREEN}`, {
                            state: {
                                screenName: props.screenName,
                                workplatformDetails: props?.workplatformDetails,
                            },
                        })
                        }}
                    />
                    <img
                        src={closeIcon}
                        className=""
                        alt="close_icon"
                        onClick={() => {
                          dispatchLoaderContext(true);
                          navigate(`/${TERMINATE_POPUP}`, {
                            state: {
                              exitReason: props.exitReason || "EXIT_FROM_APP",
                              screenName: props.screenName,
                          },
                          });
                        }}
                    />
                </div>
            ) : null}
        </div>
    );
}

export default ScreenNavbar;
