import React from "react";
import AllAccountsContext from "./context/AllAccountsContext";
// import AnalyticsContext from "./context/AnalyticsContext";
import LoaderWithStatusContext from "./context/LoaderWithStatusContext";
import ConfigContext from "./context/ConfigContext";
import SDKInstanceContext from "./context/SdkInstanceContext";
import SDKParamsContext from "./context/SdkParmasContext";
import WorkPlatformDetailsContext from "./context/WorkPlatformDetailsContext";
import Main from "./pages/Main/Main";
import LoaderContext from "./context/LoaderContext";

export default function App() {
  return (
    <SDKParamsContext>
      <WorkPlatformDetailsContext>
        <SDKInstanceContext>
          <ConfigContext>
            <AllAccountsContext>
              <LoaderWithStatusContext>
                <LoaderContext>
                  <Main />
                </LoaderContext>
              </LoaderWithStatusContext>
            </AllAccountsContext>
          </ConfigContext>
        </SDKInstanceContext>
      </WorkPlatformDetailsContext>
    </SDKParamsContext>
  );
}
