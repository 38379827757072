import React from "react";
import { Carousel } from "react-bootstrap";
import CardTitle from "../CardTitle/CardTitle";
import { ReactComponent as SpiralConnection } from "../../assets/images/spiral.svg";
import { ReactComponent as PhylloIcon } from "../../assets/images/phyllo_icon.svg";
import "./LongLoader.scss";
import CardContainer from "../CardContainer/CardContainer";
import ScreenNavbar from "../ScreenNavbar/ScreenNavbar";

function LongLoader({
  longLoaderStringConstant,
  work_platform_name,
  logo_url,
  long_loader_texts,
  screenName,
  workPlatformId,
}) {
  return (
    <CardContainer>
      <ScreenNavbar
        noBackButton
        screenName={screenName}
        workplatformDetails={{
          workplatformId: workPlatformId,
          workplatformName: work_platform_name,
        }}
      />
      <div className="polling-long-loader-container">
        {/* TODO: replace substack with workplatform.name */}
        <div>
          <CardTitle
            className="platform-connecting-title"
            style={{ marginBottom: 0 }}
          >
            {longLoaderStringConstant.title.replace(
              "{{PlatformName}}",
              work_platform_name || ""
            )}
          </CardTitle>
          <div className="description-tiny">
            <span>{longLoaderStringConstant.subtitle}</span>
          </div>
        </div>
        <div className="phyllo-connecting-platform-animation">
          {/* <PhylloConnectPlatform className="spiral-image" /> */}
          <div className="platform-icon-background platform-icon-with-circular-background">
            <PhylloIcon />
          </div>
          <SpiralConnection className="spiral-image" />
          <div className="platform-icon-background platform-icon-with-circular-background">
            <img src={logo_url} />
          </div>
        </div>

        <div>
          <CardTitle className="faded-title">
            {longLoaderStringConstant.carousel_header || ""}
          </CardTitle>
          <div className="animating-description-carousel">
            {long_loader_texts && (
              <Carousel controls={false} indicators={false}>
                {long_loader_texts.map((description) => {
                  return (
                    <Carousel.Item
                      className="description-tiny"
                      key={description}
                    >
                      <span>{description}</span>
                    </Carousel.Item>
                  );
                })}
              </Carousel>
            )}
          </div>
        </div>
      </div>
    </CardContainer>
  );
}

export default LongLoader;
