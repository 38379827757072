import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./LinkedinProfileAuth.scss";
import {
  CardContainer,
  LabelledInput,
  PrimaryButton,
  ScreenNavbar,
} from "../../components";
import {
  BEEHIIV_LONG_LOADER_SCREEN,
  LINKEDIN_PROFILE_AUTH_SCREEN,
} from "../../analytics/eventConstants";
import linkedInProfileURLINFO from "../../assets/images/LinkedinProfileURL.gif";
import {
  SOMETHING_WENT_WRONG,
  STATUS_CONNECTED,
  STATUS_FAILED,
} from "../../constants/URLConstants";
import { SDKInstanceState } from "../../context/SdkInstanceContext";
import { ConfigState } from "../../context/ConfigContext";
import BeeHiivLongLoader from "../BeeHiivLongLoader/BeeHiivLongLoader";
import { WorkPlatformDetailsState } from "../../context/WorkPlatformDetailsContext";
import WarningIcon from "../../assets/images/warning_icon.svg";
import { validateLinkedInProfileURL } from "../../utils/sdk_utils";

const SHORT_LOADER_TIMER = 3000;
let timer;
function LinkedinProfileAuth() {
  const params = useParams();
  const { authorizationid } = params;
  const location = useLocation();
  const navigate = useNavigate();
  const { sdkInstance } = SDKInstanceState();
  const { configuration } = ConfigState();

  const { workPlatformId } = location.state;
  if (!workPlatformId) {
    console.log("workplatform id not found");
    navigate(`/${SOMETHING_WENT_WRONG}`);
    return;
  }
  const { workplatformDetails } = WorkPlatformDetailsState();
  const [platformDetail, setPlatformDetail] = useState(
    workplatformDetails[workPlatformId]
  );
  const [profileLink, setProfileLink] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);

  // state for loader component
  const [isLoading, setIsLoading] = useState(false);
  const [useLongLoader, setUseLongLoader] = useState(false);

  // destructing config screen with custom name
  let shortLoaderStringConstants;
  let longLoaderStringConstant;
  if (Object.keys(configuration).length) {
    const { screens } = configuration;
    shortLoaderStringConstants = screens.short_loader_screen;
    longLoaderStringConstant = screens.long_loader_screen;
  }

  const handleProfileLinkSubmit = async (e) => {
    // start loader component
    setIsLoading(true);
    // transistion timer from short to long loader
    timer = setTimeout(() => {
      if (setUseLongLoader) setUseLongLoader(true);
    }, SHORT_LOADER_TIMER);
    await postProfileURL();
    // clear timeout to avoid unwanted state changes
    if (timer) clearTimeout(timer);
    // reset loader component states and move to Auth Comp
    if (setProfileLink) setProfileLink(null);
    if (setUseLongLoader) setUseLongLoader(false);
    if (setIsLoading) setIsLoading(false);
  };

  const postProfileURL = async () => {
    let response = await sdkInstance.postConnectWithPlatform({
      workPlatformId,
      authorization_id: authorizationid,
      platform_profile_url: profileLink,
    });

    // success
    if (response?.status === 200) {
      response = await response.json();
      // get acc ids
      const acc_ids = response.data.map((acc) => {
        return acc.id;
      });
      // navigate to account connect
      navigate(`/${STATUS_CONNECTED}/${acc_ids}`);
    } else if (response?.status === 401) {
      if (setErrorMsg)
        setErrorMsg("This is an invalid link, please enter the correct link.");
    } else {
      navigate(`/${STATUS_FAILED}/${authorizationid}`);
    }
  };
  return isLoading ? (
    <BeeHiivLongLoader
      useLongLoader={useLongLoader}
      shortLoaderStringConstants={shortLoaderStringConstants}
      longLoaderStringConstant={longLoaderStringConstant}
      logo_url={platformDetail?.logo_url}
      long_loader_texts={platformDetail?.sdk_loader_config?.long_loader_texts}
      screenName={BEEHIIV_LONG_LOADER_SCREEN}
      workPlatformId={platformDetail?.id}
      work_platform_name={platformDetail?.name}
    />
  ) : (
    <div className="page-background linkedin-profile-auth-container">
      <CardContainer>
        <ScreenNavbar
          noBackButton
          screenName={LINKEDIN_PROFILE_AUTH_SCREEN}
          workplatformDetails={{
            workplatformId: platformDetail?.id,
            workplatformName: platformDetail?.name,
          }}
        />
        <div className="platform-content">
          <div className="platform-icon">
            <img src={platformDetail?.logo_url} />
          </div>
          <div className="platform-title">
            <p>Almost there, one last step! </p>
          </div>
          <div className="platform-description">
            <p>
              To establish a secure connection, please provide your LinkedIn
              profile link.
            </p>
          </div>
          <div className="info-img-container">
            <img width="100%" height="100%" src={linkedInProfileURLINFO} />
          </div>
          <div className="input-container">
            <LabelledInput
              label="LinkedIn profile link"
              placeholder="Paste your profile link here"
              containerProps={{
                style: { marginBottom: "12px" },
              }}
              name="profile_link"
              errorMsg={
                !validateLinkedInProfileURL(profileLink) && errorMsg ? (
                  <>
                    <img
                      src={WarningIcon}
                      alt="warning-icon"
                      style={{ verticalAlign: "sub", marginRight: "4px" }}
                    />
                    {errorMsg}
                  </>
                ) : null
              }
              style={
                !validateLinkedInProfileURL(profileLink) && errorMsg
                  ? {
                      border: "1.5px solid #AD3737",
                      marginBottom: "8px",
                      textOverflow: "ellipsis",
                      padding: "16px",
                    }
                  : { textOverflow: "ellipsis", padding: "16px" }
              }
              onChange={(ev) => {
                if (!validateLinkedInProfileURL(ev.target.value)) {
                  setErrorMsg(
                    "This is an invalid link, please enter the correct link."
                  );
                }
                setProfileLink(ev.target.value);
              }}
              // style={(loginErrorCode && !credentials.username) || validationError.username ? { borderColor: ERROR_COLOR } : {}}
              className="beehiiv-input-field"
            />
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.linkedin.com/help/linkedin/answer/a522735/find-your-linkedin-public-profile-url?lang=en"
              className="profile-info-link"
            >
              Where can I find this?
            </a>
          </div>
        </div>
        <PrimaryButton
          //   onClick={}
          style={
            validateLinkedInProfileURL(profileLink) ? {} : { opacity: 0.5 }
          }
          disabled={!validateLinkedInProfileURL(profileLink)}
          //   className="primary-btn"
          onClick={handleProfileLinkSubmit}
        >
          Proceed
        </PrimaryButton>
      </CardContainer>
    </div>
  );
}

export default LinkedinProfileAuth;
