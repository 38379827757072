import { createContext, useContext, useReducer, useState } from "react";

export const LoaderWithStatus = createContext();

function LoaderWithStatusContext({ children }) {
  const [isConnectLoader, setIsConnectLoader] = useState(false);
  const [windowPopup, setWindowPopup] = useState({});

  return (
    <LoaderWithStatus.Provider
      value={{
        isConnectLoader,
        setIsConnectLoader,
        windowPopup,
        setWindowPopup,
      }}
    >
      {children}
    </LoaderWithStatus.Provider>
  );
}

export const LoaderWithStatusState = () => useContext(LoaderWithStatus);

export default LoaderWithStatusContext;
