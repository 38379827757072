import React from "react";
import "./CardContainer.scss";

export default function CardContainer({ children, className, ...otherProps }) {
  return (
    <div className={`card-container ${className || ""}`} {...otherProps}>
      {children}
    </div>
  );
}
