import { REDIRECT } from "../analytics/eventConstants";
import { trackEvent } from "../analytics/sdkAnalytics";
import {
    PHYLLO_CONNECTED_ACCOUNT,
    PHYLLO_CONNECTION_INFO,
    PHYLLO_CONNECTION_STATUS,
    PHYLLO_CONNECTION_SUCCESSFUL,
    PHYLLO_CONNECTION_UNSUCCESSFUL,
    PHYLLO_DISCONNECTION_INFO,
    PHYLLO_REASON,
    PHYLLO_userId,
} from "../constants/constants";

export const getRedirectParams = async (redirectURL, reason, userId) => {
    const connectedAccountInfo = [];
    const disConnectedAccountInfo = [];
    // const accountDetails = [];

    // if(sessionStorage.getItem('accountIdsObj')) {
    //     let accountIdsObj = JSON.parse(sessionStorage.getItem('accountIdsObj'))
    //     await Promise.all(accountIdsObj.map(async (accountId) => {
    //         const accountInfo = await sdkInstance.getAccountInfo(accountId);
    //         accountDetails.push(accountInfo)
    //       }));
    // }
    try {
        const accountDetails = JSON.parse(sessionStorage.getItem("accountIdsObj"));
        if (accountDetails) {
            Object.keys(accountDetails).forEach((accountId) => {
                if (accountDetails[accountId].status === PHYLLO_CONNECTED_ACCOUNT) {
                    connectedAccountInfo.push({
                        account_id: accountId,
                        work_platform_id: accountDetails[accountId].workplatformId,
                    });
                } else {
                    disConnectedAccountInfo.push({
                        account_id: accountId,
                        work_platform_id: accountDetails[accountId]?.workplatformId,
                    });
                }
            });
        }

        const statusToSend =
            Object.keys(connectedAccountInfo).length > 0 || Object.keys(disConnectedAccountInfo).length > 0
                ? PHYLLO_CONNECTION_SUCCESSFUL
                : PHYLLO_CONNECTION_UNSUCCESSFUL;
        // For clearing the session storage of accountId and context in redirect flow
        sessionStorage.removeItem("accountIdsObj");
        sessionStorage.removeItem("sdkParams");
        sessionStorage.removeItem("workplatformDetails");

        const url = new URL(redirectURL);
        // url.searchParams.append(PHYLLO_CONNECTION_STATUS,statusToSend)
        url.searchParams.append(PHYLLO_userId, userId);
        if (statusToSend === PHYLLO_CONNECTION_SUCCESSFUL) {
            if (Object.keys(connectedAccountInfo).length > 0) {
                url.searchParams.append(PHYLLO_CONNECTION_INFO, JSON.stringify(connectedAccountInfo));
            }
            if (Object.keys(disConnectedAccountInfo).length > 0) {
                url.searchParams.append(PHYLLO_DISCONNECTION_INFO, JSON.stringify(disConnectedAccountInfo));
            }
            // url.searchParams.append(PHYLLO_REASON, reason);
            // window.location.replace(decodeURIComponent(url));
        }
        url.searchParams.append(PHYLLO_REASON, reason);
        window.location.replace(decodeURIComponent(url));
        // Tracking event : REDIRECT
        trackEvent(REDIRECT, {
            from: window.location.href,
            to: window.location.replace(decodeURIComponent(url)),
        });
    } catch (err) {
        window.location.replace(decodeURIComponent(redirectURL));
        console.log(err);
        throw err;
    }
};
