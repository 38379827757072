/* eslint-disable */
import { trackEvent } from "../analytics/sdkAnalytics";
import {
  API_ENDPOINT_URL,
  BEEHIIV_WORKPLATFORM_ID,
  DEV,
  environmentConfig,
  LINKEDIN_WORKPLATFORM_ID,
  PHYLLO_API_MAX_ATTEMPTS,
  PHYLLO_API_POLLING_INTERVAL,
  PHYLLO_API_RETRY_ERROR_CODES,
  PHYLLO_EXIT_REASON_TOKEN_EXPIRED,
  PHYLLO_ON_TOKEN_EXPIRED_KEY,
  PROD,
  SANDBOX,
} from "../constants/constants";
import { getRedirectParams } from "./getRedirectParams";
import { handlePromise } from "./sdk_utils";

// const SANDBOX = "sandbox";
// const PROD = "production";
// const DEV = "dev";
// const DEV2 = "dev2";
// const DEV3 = "dev3";
// const PREPROD = "preprod";
// const SANDBOX_URL = "https://api.sandbox.getphyllo.com";
// const PROD_URL = "https://api.getphyllo.com";
// // const DEV_URL = "https://api.dev.getphyllo.com";
// const DEV_URL = process.env.REACT_APP_API_ENDPOINT;
// const LOCAL_DEV_URL = "http://localhost:8000";
// // const DEV_URL = LOCAL_DEV_URL;
// const PREPROD_URL = "https://api.preprod.getphyllo.com";

export default class Phyllo {
  constructor(data) {
    // Validate the request data.

    /*
      User id, token and auth events are required parameters in SDK.
    */
    // TODO: Provide link in the exceptions so, user can directly redirect to that specific error.
    if (!data.userID) {
      const err = new Error("userId is required.");
      throw err;
    }
    // TODO: Provide link in the exceptions so, user can directly redirect to that specific error.
    if (!data.userID) {
      const err = new Error("userId is required.");
      throw err;
    }

    if (!data.token) {
      const err = new Error("Token is required.");
      throw err;
    }
    if (!data.token) {
      const err = new Error("Token is required.");
      throw err;
    }

    // Initialize the tokens, userid, events etc.
    this.token = data.token;
    this.userID = data.userID;
    this.redirectURL = data.redirectURL;
    this.appName = data.appName;
    // Initialize the tokens, userid, events etc.
    this.token = data.token;
    this.userID = data.userID;
    this.redirectURL = data.redirectURL;
    this.appName = data.appName;
    this.language = data.language;

    // Initialize the API.
    // if (env === SANDBOX) {
    //   this.baseDomain = SANDBOX_URL;
    // } else if (env === PROD) {
    //   this.baseDomain = PROD_URL;
    // } else if (env === DEV || env === DEV2 || env === DEV3) {
    //   this.baseDomain = DEV_URL;
    // } else if (env === PREPROD) {
    //   this.baseDomain = PREPROD_URL;
    // } else {
    if (!environmentConfig.includes(data.env))
      throw new Error("Please pass a valid environment");
    else this.baseDomain = API_ENDPOINT_URL;
    // Define urls.
    // this._URL_GET_CONFIGURATION = `https://2ad4943c-8c93-4be1-883b-cc97ac713f25.mock.pstmn.io/config?lang={lang}`;
    this._URL_GET_CONFIGURATION = `${this.baseDomain}/v1/internal/config?lang={lang}`;
    this._URL_GET_AUTHORIZATIONS = `${this.baseDomain}/v1/internal/authorizations`;
    this._URL_GET_AUTHORIZATIONS_DETAILS = `${this.baseDomain}/v1/internal/authorizations/{id}`;
    this._URL_POST_AUTHORIZATIONS_CANCEL = `${this.baseDomain}/v1/internal/authorizations/{id}/cancel`;
    // this._URL_GET_WORK_PLATFORMS = `https://2c7c7a72-921c-48a2-b7f8-28981372fc99.mock.pstmn.io/workplatforms`;
    this._URL_GET_WORK_PLATFORMS = `${this.baseDomain}/v1/internal/work-platforms`;
    this._URL_GET_ALL_ACCOUNTS = `${this.baseDomain}/v1/internal/accounts`;
    this._URL_LOGIN_SESSION = `${this.baseDomain}/v1/internal/login/sessions`;
    this._URL_LOGOUT_ACCOUNT = `${this.baseDomain}/v1/internal/accounts/{id}/logout`;
    this._URL_GET_BEEHIIV_PUBLICATIONS = `${this.baseDomain}/v1/internal/authorizations/list-publications`;
    this._URL_POST_CONNECT_ENDPOINT = `${this.baseDomain}/v1/internal/authorizations/{id}/connect`;
    // common headers
    this.commonHeaders = this._getCommonHeaders();
  }

  _getCommonHeaders() {
    const headers = new Headers();
    headers.append("Authorization", `Bearer ${this.token}`);
    headers.append("sdk-type", "WEB");
    headers.append("sdk-version", "1.0");
    headers.append("content-type", "application/json");
    return headers;
  }

  getAppName() {
    return this.appName || "";
  }

  async getConfiguration() {
    try {
      for (let attempt = 1; attempt <= PHYLLO_API_MAX_ATTEMPTS; attempt++) {
        const [response, fetchError] = await handlePromise(
          fetch(this._URL_GET_CONFIGURATION.replace("{lang}", this.language), {
            method: "GET",
            headers: this.commonHeaders,
            redirect: "follow",
          })
        );
        if (fetchError) {
          throw new Error(
            `${fetchError} occurred while calling getConfigurations`
          );
        }
        if (response.status === 401) {
          // if Redirect param is passed
          if (this.redirectURL !== "null") {
            getRedirectParams(
              this.redirectURL,
              PHYLLO_EXIT_REASON_TOKEN_EXPIRED,
              this.userID
            );
          } else {
            // Sending onExit callback
            window.parent.postMessage(
              {
                [PHYLLO_ON_TOKEN_EXPIRED_KEY]: {
                  userId: this.userID,
                },
              },
              "*"
            );
          }
        } else if (PHYLLO_API_RETRY_ERROR_CODES.includes(response.status)) {
          await new Promise((resolve) =>
            setTimeout(resolve, PHYLLO_API_POLLING_INTERVAL)
          );
        } else {
          const [json, jsonError] = await handlePromise(response.json());
          if (jsonError) {
            throw new Error(
              `${json} has not received valid json for getConfigurations`
            );
          }
          return json;
        }
      }
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  async getAuthorizations(workPlatformId, redirectURLPostOAuth) {
    if (!workPlatformId) {
      const err = new Error("Work platform id cannot be null or blank");
      throw err;
    }

    const headers = this._getCommonHeaders();
    try {
      for (let attempt = 1; attempt <= PHYLLO_API_MAX_ATTEMPTS; attempt++) {
        const response = await fetch(this._URL_GET_AUTHORIZATIONS, {
          method: "POST",
          headers,
          redirect: "follow",
          body: JSON.stringify({
            work_platform_id: workPlatformId,
            redirect_uri: redirectURLPostOAuth,
          }),
        });

        try {
          if (response.status === 401) {
            // if Redirect param is passed
            if (this.redirectURL !== "null") {
              getRedirectParams(
                this.redirectURL,
                PHYLLO_EXIT_REASON_TOKEN_EXPIRED,
                this.userID
              );
            } else {
              // Sending onExit callback
              window.parent.postMessage(
                {
                  [PHYLLO_ON_TOKEN_EXPIRED_KEY]: {
                    userId: this.userID,
                  },
                },
                "*"
              );
            }
          } else if (PHYLLO_API_RETRY_ERROR_CODES.includes(response.status)) {
            await new Promise((resolve) =>
              setTimeout(resolve, PHYLLO_API_POLLING_INTERVAL)
            );
          } else return response.json();
        } catch (err) {
          console.error(
            `${response} has not received valid json for getAuthorizations`
          );
          throw err;
        }
      }
    } catch (err) {
      console.error(`${err} occurred while calling getAuthorizations`);
      throw err;
    }
  }

  async getAuthorizationInfo(authorizationId) {
    if (!authorizationId) {
      const err = new Error("Authorization ID cannot be null or blank");
      throw err;
    }

    const headers = this._getCommonHeaders();
    try {
      for (let attempt = 1; attempt <= PHYLLO_API_MAX_ATTEMPTS; attempt++) {
        const response = await fetch(
          this._URL_GET_AUTHORIZATIONS_DETAILS.replace("{id}", authorizationId),
          {
            method: "GET",
            headers,
            redirect: "follow",
          }
        );

        try {
          if (response.status === 401) {
            // if Redirect param is passed
            if (this.redirectURL !== "null") {
              getRedirectParams(
                this.redirectURL,
                PHYLLO_EXIT_REASON_TOKEN_EXPIRED,
                this.userID
              );
            } else {
              // Sending onExit callback
              window.parent.postMessage(
                {
                  [PHYLLO_ON_TOKEN_EXPIRED_KEY]: {
                    userId: this.userID,
                  },
                },
                "*"
              );
            }
          } else if (PHYLLO_API_RETRY_ERROR_CODES.includes(response.status)) {
            await new Promise((resolve) =>
              setTimeout(resolve, PHYLLO_API_POLLING_INTERVAL)
            );
          } else return response.json();
        } catch (err) {
          console.error(
            `${response} has not received valid json for getAuthorizations`
          );
          throw err;
        }
      }
    } catch (err) {
      console.error(`${err} occurred while calling getAuthorizations`);
      throw err;
    }
  }

  async postAuthorizationCancel(authorizationId) {
    const headers = this._getCommonHeaders();
    try {
      for (let attempt = 1; attempt <= PHYLLO_API_MAX_ATTEMPTS; attempt++) {
        const response = await fetch(
          this._URL_POST_AUTHORIZATIONS_CANCEL.replace("{id}", authorizationId),
          {
            method: "POST",
            headers,
            redirect: "follow",
          }
        );

        try {
          if (response.status === 401) {
            // if Redirect param is passed
            if (this.redirectURL !== "null") {
              getRedirectParams(
                this.redirectURL,
                PHYLLO_EXIT_REASON_TOKEN_EXPIRED,
                this.userID
              );
            } else {
              // Sending onExit callback
              window.parent.postMessage(
                {
                  [PHYLLO_ON_TOKEN_EXPIRED_KEY]: {
                    userId: this.userID,
                  },
                },
                "*"
              );
            }
          } else if (PHYLLO_API_RETRY_ERROR_CODES.includes(response.status)) {
            await new Promise((resolve) =>
              setTimeout(resolve, PHYLLO_API_POLLING_INTERVAL)
            );
          } else return response.json();
        } catch (err) {
          console.error(
            `${response} has not received valid json for getAuthorizations`
          );
          throw err;
        }
      }
    } catch (err) {
      console.error(`${err} occurred while calling getAuthorizations`);
      throw err;
    }
  }

  async getAllWorkPlatforms() {
    try {
      for (let attempt = 1; attempt <= PHYLLO_API_MAX_ATTEMPTS; attempt++) {
        const [response, fetchError] = await handlePromise(
          fetch(
            `${this._URL_GET_WORK_PLATFORMS}?limit=100&lang=${this.language}`,
            {
              method: "GET",
              headers: this.commonHeaders,
              redirect: "follow",
            }
          )
        );
        if (fetchError) {
          throw new Error(
            `${fetchError} occurred while calling getAllWorkPlatforms`
          );
        }
        if (response.status === 401) {
          // if Redirect param is passed
          if (this.redirectURL !== "null") {
            getRedirectParams(
              this.redirectURL,
              PHYLLO_EXIT_REASON_TOKEN_EXPIRED,
              this.userID
            );
          } else {
            // Sending onExit callback
            window.parent.postMessage(
              {
                [PHYLLO_ON_TOKEN_EXPIRED_KEY]: {
                  userId: this.userID,
                },
              },
              "*"
            );
          }
        } else if (PHYLLO_API_RETRY_ERROR_CODES.includes(response.status)) {
          await new Promise((resolve) =>
            setTimeout(resolve, PHYLLO_API_POLLING_INTERVAL)
          );
        } else {
          const [json, jsonError] = await handlePromise(response.json());
          if (jsonError)
            throw new Error(
              `${jsonError} has not received valid json for getAllWorkPlatforms`
            );
          return json;
        }
      }
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  async getWorkPlatform(workPlatformId) {
    try {
      for (let attempt = 1; attempt <= PHYLLO_API_MAX_ATTEMPTS; attempt++) {
        const [response, fetchError] = await handlePromise(
          fetch(
            `${this._URL_GET_WORK_PLATFORMS}/${workPlatformId}?lang=${this.language}`,
            {
              method: "GET",
              headers: this.commonHeaders,
              redirect: "follow",
            }
          )
        );

        if (fetchError)
          throw new Error(
            `${fetchError} occurred while calling workplatform - ${workPlatformId}`
          );

        if (response.status === 401) {
          // if Redirect param is passed
          if (this.redirectURL !== "null") {
            getRedirectParams(
              this.redirectURL,
              PHYLLO_EXIT_REASON_TOKEN_EXPIRED,
              this.userID
            );
          } else {
            // Sending onExit callback
            window.parent.postMessage(
              {
                [PHYLLO_ON_TOKEN_EXPIRED_KEY]: {
                  userId: this.userID,
                },
              },
              "*"
            );
          }
        } else if (PHYLLO_API_RETRY_ERROR_CODES.includes(response.status)) {
          await new Promise((resolve) =>
            setTimeout(resolve, PHYLLO_API_POLLING_INTERVAL)
          );
        } else {
          const [json, jsonError] = await handlePromise(response.json());
          if (jsonError)
            throw new Error(
              `${json} has not received valid json for workplatform - ${workPlatformId}`
            );
          return json;
        }
      }
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  async getAllAccounts() {
    try {
      for (let attempt = 1; attempt <= PHYLLO_API_MAX_ATTEMPTS; attempt++) {
        const [response, fetchError] = await handlePromise(
          fetch(`${this._URL_GET_ALL_ACCOUNTS}?limit=100`, {
            method: "GET",
            headers: this.commonHeaders,
            redirect: "follow",
            mode: "cors",
          })
        );
        if (fetchError)
          throw new Error(
            `${fetchError} occurred while calling getAllAccounts`
          );
        if (response.status === 401) {
          // if Redirect param is passed
          if (this.redirectURL !== "null") {
            getRedirectParams(
              this.redirectURL,
              PHYLLO_EXIT_REASON_TOKEN_EXPIRED,
              this.userID
            );
          } else {
            // Sending onExit callback
            window.parent.postMessage(
              {
                [PHYLLO_ON_TOKEN_EXPIRED_KEY]: {
                  userId: this.userID,
                },
              },
              "*"
            );
          }
        } else if (PHYLLO_API_RETRY_ERROR_CODES.includes(response.status)) {
          await new Promise((resolve) =>
            setTimeout(resolve, PHYLLO_API_POLLING_INTERVAL)
          );
        } else {
          const [json, jsonError] = await handlePromise(response.json());
          if (jsonError)
            throw new Error(
              `${jsonError} has not received valid json for getAllAccounts`
            );
          return json;
        }
      }
    } catch (err) {
      console.error(err);
      return {};
      // throw err;
    }
  }

  async getAccountInfo(accountId) {
    const headers = this._getCommonHeaders();
    try {
      for (let attempt = 1; attempt <= PHYLLO_API_MAX_ATTEMPTS; attempt++) {
        const response = await fetch(
          `${this._URL_GET_ALL_ACCOUNTS}/${accountId}`,
          {
            method: "GET",
            headers,
            redirect: "follow",
          }
        );
        try {
          if (response.status === 200) {
            return response.json();
          }
          if (response.status === 401) {
            // if Redirect param is passed
            if (this.redirectURL !== "null") {
              getRedirectParams(
                this.redirectURL,
                PHYLLO_EXIT_REASON_TOKEN_EXPIRED,
                this.userID
              );
            } else {
              // Sending onExit callback
              window.parent.postMessage(
                {
                  [PHYLLO_ON_TOKEN_EXPIRED_KEY]: {
                    userId: this.userID,
                  },
                },
                "*"
              );
            }
          } else if (PHYLLO_API_RETRY_ERROR_CODES.includes(response.status)) {
            await new Promise((resolve) =>
              setTimeout(resolve, PHYLLO_API_POLLING_INTERVAL)
            );
          } else {
            throw new Error(`Error Fetching ${accountId}`);
          }
        } catch (err) {
          console.error(
            `${response} has not received valid json for get account info`
          );
          throw err;
        }
      }
    } catch (err) {
      console.error(`${err} occurred while calling getAllAccounts`);
      // throw err;
      return {};
    }
  }

  async logoutAccount(accountId) {
    const headers = this._getCommonHeaders();
    try {
      for (let attempt = 1; attempt <= PHYLLO_API_MAX_ATTEMPTS; attempt++) {
        const response = await await fetch(
          `${this._URL_LOGOUT_ACCOUNT}`.replace("{id}", accountId),
          {
            method: "GET",
            headers,
            redirect: "follow",
          }
        );

        try {
          if (response.status === 401) {
            // if Redirect param is passed
            if (this.redirectURL !== "null") {
              getRedirectParams(
                this.redirectURL,
                PHYLLO_EXIT_REASON_TOKEN_EXPIRED,
                this.userID
              );
            } else {
              // Sending onExit callback
              window.parent.postMessage(
                {
                  [PHYLLO_ON_TOKEN_EXPIRED_KEY]: {
                    userId: this.userID,
                  },
                },
                "*"
              );
            }
          } else if (PHYLLO_API_RETRY_ERROR_CODES.includes(response.status)) {
            await new Promise((resolve) =>
              setTimeout(resolve, PHYLLO_API_POLLING_INTERVAL)
            );
          } else return response.json();
        } catch (err) {
          console.error(
            `${response} has not received valid json for logoutAccount`
          );
          throw err;
        }
      }
    } catch (err) {
      console.error(`${err} occurred while calling getAllAccounts`);
      throw err;
    }
  }

  async createLoginSession({ loginType, workPlatformId, credentials }) {
    const headers = this._getCommonHeaders();
    // const body = {
    //   login_type: loginType,
    //   work_platform_id: workPlatformId,
    //   credential: credentials,
    // };
    // console.log(workPlatformId);
    try {
      // const response = await this.requestInstance.post(
      //   this._URL_LOGIN_SESSION,
      //   body,
      //   headers : headers,
      // );
      // console.log(response);
      // return response;
      for (let attempt = 1; attempt <= PHYLLO_API_MAX_ATTEMPTS; attempt++) {
        const response = await fetch(this._URL_LOGIN_SESSION, {
          method: "POST",
          headers,
          body: JSON.stringify({
            login_type: loginType,
            work_platform_id: workPlatformId,
            credentials,
          }),
          redirect: "follow",
        });
        try {
          if (response.status === 401) {
            // if Redirect param is passed
            if (this.redirectURL !== "null") {
              getRedirectParams(
                this.redirectURL,
                PHYLLO_EXIT_REASON_TOKEN_EXPIRED,
                this.userID
              );
            } else {
              // Sending onExit callback
              window.parent.postMessage(
                {
                  [PHYLLO_ON_TOKEN_EXPIRED_KEY]: {
                    userId: this.userID,
                  },
                },
                "*"
              );
            }
          } else if (PHYLLO_API_RETRY_ERROR_CODES.includes(response.status)) {
            await new Promise((resolve) =>
              setTimeout(resolve, PHYLLO_API_POLLING_INTERVAL)
            );
          } else return response.json();
        } catch (err) {
          console.error(
            `${response} has not received valid json for createLoginSession`
          );
          throw err;
        }
      }
    } catch (err) {
      console.log(`${err} occured while calling create login session`);
      throw err;
    }
  }

  async sumbitLoginOTP({ loginSessionId, workPlatformId, credentials }) {
    const headers = this._getCommonHeaders();
    // const body = {
    //   work_platform_id: workPlatformId,
    //   credential: credentials,
    // };

    try {
      // const response = await this.requestInstance.post(
      //   `${this._URL_LOGIN_SESSION}/${loginSessionId}/submit-mfa`,
      //   body
      // );
      // return response;
      const response = await fetch(
        `${this._URL_LOGIN_SESSION}/${loginSessionId}/submit-mfa`,
        {
          method: "POST",
          headers,
          body: JSON.stringify({
            work_platform_id: workPlatformId,
            credentials,
          }),
          redirect: "follow",
        }
      );
      try {
        if (response.status === 401) {
          // if Redirect param is passed
          if (this.redirectURL !== "null") {
            getRedirectParams(
              this.redirectURL,
              PHYLLO_EXIT_REASON_TOKEN_EXPIRED,
              this.userID
            );
          } else {
            // Sending onExit callback
            window.parent.postMessage(
              {
                [PHYLLO_ON_TOKEN_EXPIRED_KEY]: {
                  userId: this.userID,
                },
              },
              "*"
            );
          }
        } else if (PHYLLO_API_RETRY_ERROR_CODES.includes(response.status)) {
          await new Promise((resolve) =>
            setTimeout(resolve, PHYLLO_API_POLLING_INTERVAL)
          );
        } else return response.json();
      } catch (err) {
        console.error(
          `${response} has not received valid json for sumbitLoginOTP`
        );
        throw err;
      }
    } catch (err) {
      console.log(`${err} occured while calling submit mfa`);
      throw err;
    }
  }

  async submitMagicLink({ loginSessionId, magicLinkURL }) {
    const headers = this._getCommonHeaders();
    try {
      const response = await fetch(
        `${this._URL_LOGIN_SESSION}/${loginSessionId}/submit-magic-link-url`,
        {
          method: "POST",
          headers,
          body: JSON.stringify({
            magic_link_url: magicLinkURL,
          }),
          redirect: "follow",
        }
      );
      try {
        if (response.status === 401) {
          // if Redirect param is passed
          if (this.redirectURL !== "null") {
            getRedirectParams(
              this.redirectURL,
              PHYLLO_EXIT_REASON_TOKEN_EXPIRED,
              this.userID
            );
          } else {
            // Sending onExit callback
            window.parent.postMessage(
              {
                [PHYLLO_ON_TOKEN_EXPIRED_KEY]: {
                  userId: this.userID,
                },
              },
              "*"
            );
          }
        } else if (PHYLLO_API_RETRY_ERROR_CODES.includes(response.status)) {
          await new Promise((resolve) =>
            setTimeout(resolve, PHYLLO_API_POLLING_INTERVAL)
          );
        } else return response.json();
      } catch (err) {
        console.error(
          `${response} has not received valid json for sumbitLoginOTP`
        );
        throw err;
      }
    } catch (err) {
      console.log(`${err} occured while calling submit mfa`);
      throw err;
    }
  }
  /**
   * Asynchronously retrieves publications from a work platform using provided credentials.
   *
   * @param {object} param - An object containing work platform ID and credentials.
   * @param {string} param.workPlatformId - The ID of the work platform to retrieve publications from.
   * @param {object} param.credentials - An object containing credentials necessary to authenticate.
   * @param {string} param.credentials.api_key - The API key used to authenticate.
   * @return {Promise<object>} A Promise that resolves to an object representing the retrieved publications.
   *                           If an error occurs, it will reject with the error.
   */
  async getBeehiivPublications({ workPlatformId, credentials }) {
    const headers = this._getCommonHeaders();
    let response;
    try {
      response = await fetch(`${this._URL_GET_BEEHIIV_PUBLICATIONS}`, {
        method: "POST",
        headers,
        body: JSON.stringify({
          work_platform_id: workPlatformId,
          credentials,
        }),
        redirect: "follow",
      });

      try {
        if (response.status === 401) {
          // if Redirect param is passed
          if (this.redirectURL !== "null") {
            getRedirectParams(
              this.redirectURL,
              PHYLLO_EXIT_REASON_TOKEN_EXPIRED,
              this.userID
            );
          } else {
            // Sending onExit callback
            window.parent.postMessage(
              {
                [PHYLLO_ON_TOKEN_EXPIRED_KEY]: {
                  userId: this.userID,
                },
              },
              "*"
            );
          }
        } else if (PHYLLO_API_RETRY_ERROR_CODES.includes(response.status)) {
          await new Promise((resolve) =>
            setTimeout(resolve, PHYLLO_API_POLLING_INTERVAL)
          );
        } else return response;
      } catch (err) {
        console.error(
          `${response} has not received valid json for getBeehiivPublications`
        );
        throw err;
      }
    } catch (err) {
      console.log(`${err} occured while getting list of publication`);
      return response;
    }
  }

  /**
   * Asynchronously posts selected Beehiiv publications to the server.
   *
   * @param {string} workPlatformId - The work platform ID. Required for all platforms.
   * @param {string} authorization_id - The authroization id. Required for all platforms.
   * @param {object} credentials - The user's credentials. Required for beehiiv.
   * @param {string} credentials.api_key - The API key used to authenticate. Required for beehiiv.
   * @param {Array} platform_publication_ids - An array of platform publication IDs. Required for beehiiv.
   * @param {string} platform_profile_url - The platform profile url. Required for Linkedin.
   * @return {Promise<any>} A Promise that resolves with the server response.
   * @throws If there is an error sending the request.
   */
  async postConnectWithPlatform({
    workPlatformId,
    credentials,
    platform_publication_ids,
    authorization_id,
    platform_profile_url,
  }) {
    const headers = this._getCommonHeaders();
    let response;
    const body = {};
    body.work_platform_id = workPlatformId;
    if (workPlatformId === LINKEDIN_WORKPLATFORM_ID) {
      if (platform_profile_url) {
        body.platform_profile_url = platform_profile_url;
      } else {
        console.warn("platform profile url is required");
        return Promise.reject(new Error("platform profile url is required"));
      }
    } else if (workPlatformId === BEEHIIV_WORKPLATFORM_ID) {
      if (
        credentials &&
        credentials.api_key &&
        platform_publication_ids.length > 0
      ) {
        body.credentials = credentials;
        body.platform_publication_ids = platform_publication_ids;
      } else {
        console.warn(
          "credentials,credentials.api_key and platform_publication_ids are required"
        );
        return Promise.reject(
          new Error(
            "credentials,credentials.api_key and platform_publication_ids are required"
          )
        );
      }
    } else {
      console.warn("work platform id is incorrect");
      return Promise.reject(new Error("work platform id is incorrect"));
    }
    try {
      response = await fetch(
        `${this._URL_POST_CONNECT_ENDPOINT.replace("{id}", authorization_id)}`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(body),
          redirect: "follow",
        }
      );
      try {
        if (response.status === 401) {
          // if Redirect param is passed
          if (this.redirectURL !== "null") {
            getRedirectParams(
              this.redirectURL,
              PHYLLO_EXIT_REASON_TOKEN_EXPIRED,
              this.userID
            );
          } else {
            // Sending onExit callback
            window.parent.postMessage(
              {
                [PHYLLO_ON_TOKEN_EXPIRED_KEY]: {
                  userId: this.userID,
                },
              },
              "*"
            );
          }
        } else if (PHYLLO_API_RETRY_ERROR_CODES.includes(response.status)) {
          await new Promise((resolve) =>
            setTimeout(resolve, PHYLLO_API_POLLING_INTERVAL)
          );
        } else return response;
      } catch (err) {
        console.error(
          `${response} has not received valid json for postConnectWithPlatform`
        );
        throw err;
      }
    } catch (err) {
      console.log(
        `${err} occured while posting selected publications to server`
      );
      return response;
    }
  }

  async getLoginSessionDetail(sessionId) {
    const headers = this._getCommonHeaders();
    try {
      // const response = await this.requestInstance.get(
      //   this._URL_LOGIN_SESSION + `/${sessionId}`
      // );
      const response = await fetch(`${this._URL_LOGIN_SESSION}/${sessionId}`, {
        method: "GET",
        headers,
        redirect: "follow",
      });
      try {
        if (response.status === 401) {
          // if Redirect param is passed
          if (this.redirectURL !== "null") {
            getRedirectParams(
              this.redirectURL,
              PHYLLO_EXIT_REASON_TOKEN_EXPIRED,
              this.userID
            );
          } else {
            // Sending onExit callback
            window.parent.postMessage(
              {
                [PHYLLO_ON_TOKEN_EXPIRED_KEY]: {
                  userId: this.userID,
                },
              },
              "*"
            );
          }
        } else if (PHYLLO_API_RETRY_ERROR_CODES.includes(response.status)) {
          await new Promise((resolve) =>
            setTimeout(resolve, PHYLLO_API_POLLING_INTERVAL)
          );
        } else return response.json();
      } catch (err) {
        console.error(
          `${response} has not received valid json for getLoginSessionDetail`
        );
        throw err;
      }
    } catch (e) {
      throw e;
    }
  }
}
