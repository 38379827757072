import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import LogRocket from "logrocket";
import "./assets/scss/sendbox.scss";
import "./libcss";
import App from "./App";

// LogRocket.init("phyllo/connect-sdk-prod", {
//   network: {
//     requestSanitizer: (request) => {
//       // if the url contains 'delete'
//       if (request.url.toLowerCase().indexOf("login") !== -1) {
//         // scrub out the body
//         try {
//           const jsonBody = JSON.parse(request.body);
//           console.log(jsonBody);
//           if (jsonBody?.credentials?.password) {
//             jsonBody.credentials.password = "filtered";
//             request.body = JSON.stringify(jsonBody);
//           }
//         } catch (err) {
//           console.warn(err, "invalid json");
//         }
//       }
//       // make sure you return the modified request
//       return request;
//     },
//   },
// });

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_API_KEY,
    integrations: [
        new BrowserTracing(),
        new Sentry.Replay({
            // Additional SDK configuration goes in here, for example:
            maskAllText: false,
            blockAllMedia: false,
        }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
});

ReactDOM.render(<App />, document.getElementById("root"));
