import { createContext, useContext, useReducer } from "react";
import { LoadingStates, SDKInstanceReducer } from "../utils/Reducers/Reducers";

export const SDKInstance = createContext();

function SDKInstanceContext({ children }) {
  const [sdkInstance, dispatchSdkInstContext] = useReducer(SDKInstanceReducer, {
    isLoading: LoadingStates.pending,
    data: {},
  });

  const val = {
    loading: sdkInstance.isLoading,
    sdkInstance: sdkInstance.data,
    dispatchSdkInstContext,
  };

  return <SDKInstance.Provider value={val}>{children}</SDKInstance.Provider>;
}

export const SDKInstanceState = () => useContext(SDKInstance);

export default SDKInstanceContext;
