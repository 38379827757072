import React from "react";
import "./CardTitle.scss";

export default function CardTitle({ children, className = "", ...otherProps }) {
  return (
    <h2 className={`card-title ${className}`} {...otherProps}>
      {children}
    </h2>
  );
}
