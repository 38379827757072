import { createContext, useContext, useReducer } from "react";
import {
  LoadingStates,
  WorkPlatformDetailsReducer,
} from "../utils/Reducers/Reducers";

export const WorkPlatform = createContext();

function WorkPlatformDetailsContext({ children }) {
  const [workplatformDetails, dispatchWorkPltfrmContext] = useReducer(
    WorkPlatformDetailsReducer,
    {
      isLoading: LoadingStates.pending,
      data: {},
    }
  );

  const val = {
    loading: workplatformDetails.isLoading,
    workplatformDetails: workplatformDetails.data,
    dispatchWorkPltfrmContext,
  };

  return <WorkPlatform.Provider value={val}>{children}</WorkPlatform.Provider>;
}

export const WorkPlatformDetailsState = () => useContext(WorkPlatform);

export default WorkPlatformDetailsContext;
