import { LINKEDIN_PROFILE_AUTH, SOMETHING_WENT_WRONG } from "../constants/URLConstants";
import {
    LANGUAGES,
    LANGUAGES_AVAILABLE,
    LINKEDIN_WORKPLATFORM_ID,
    PHYLLO_ACCOUNT_CONNECTED_KEY,
    PHYLLO_ON_CONNECTION_FAILURE,
} from "../constants/constants";
import Phyllo from "./getphyllo-sdk";

export const getSDKInstance = async (sdkParams) => {
    const { userId, appName, env, token, redirectURL, language } = sdkParams;
    // console.log("lang", language, sdkParams);
    if (!userId) {
        throw new Error("No userId sent in config");
    }
    if (!appName) {
        throw new Error("No appName sent in config");
    }
    if (!env) {
        throw new Error("No env sent in config");
    }
    // Generate token.
    if (!token) {
        throw new Error("No token sent in config");
    }
    if (!LANGUAGES_AVAILABLE.includes(language)) {
        throw new Error("Invalid language selected. Refer docs.getphyllo.com");
    }
    // Create the instance of the phyllo sdk.
    const phylloInstance = new Phyllo({
        userID: userId,
        token,
        authEvents: {},
        env,
        appName,
        redirectURL,
        language,
    });
    return phylloInstance;
};

// returns [data,err]
export const handlePromise = (promise) => {
    return promise.then((data) => [data, null]).catch((err) => Promise.resolve([null, err]));
};

export const isEmpty = (value) => {
    return (
        value === undefined ||
        value === null ||
        (typeof value === "object" && Object.keys(value).length === 0) ||
        (typeof value === "string" && value.trim().length === 0)
    );
};
export const isNULL = (val) => {
    if (val !== "null" && val !== null) return false;
    return true;
};

export const sendingAccountConnectedCallback = (accountIds, workplatformId, sdkParams) => {
    // Sending onAccountConnected callback
    for (let i = 0; i < accountIds.length; i += 1) {
        window.parent.postMessage(
            {
                [PHYLLO_ACCOUNT_CONNECTED_KEY]: {
                    accountId: accountIds[i],
                    workplatformId,
                    userId: sdkParams.userId,
                },
            },
            "*"
        );
    }
};

export const sendingAccountConnectionFailureCallback = (reasonBody) => {
    // Sending ConnectionFailure callback
    window.parent.postMessage(
        {
            [PHYLLO_ON_CONNECTION_FAILURE]: reasonBody,
        },
        "*"
    );
};

export const componentLoader = (lazyComponent, attemptsLeft) => {
    return new Promise((resolve, reject) => {
        lazyComponent()
            .then(resolve)
            .catch((error) => {
                // let us retry after 500 ms
                setTimeout(() => {
                    if (attemptsLeft === 1) {
                        reject(error);
                        return;
                    }
                    componentLoader(lazyComponent, attemptsLeft - 1).then(resolve, reject);
                }, 500);
            });
    });
};

export const versionCompare = (v1, v2) => {
    let vnum1 = 0;
    let vnum2 = 0;
    for (let i = 0, j = 0; i < v1.length || j < v2.length; ) {
        while (i < v1.length && v1[i] !== ".") {
            vnum1 = vnum1 * 10 + (v1[i] - "0");
            i += 1;
        }

        while (j < v2.length && v2[j] !== ".") {
            vnum2 = vnum2 * 10 + (v2[j] - "0");
            j += 1;
        }

        if (vnum1 > vnum2) return 1;
        if (vnum2 > vnum1) return -1;

        // if equal, reset variables and
        // go for next numeric part
        vnum1 = 0;
        vnum2 = 0;
        i += 1;
        j += 1;
    }
    return 0;
};

export const debounce = (func, timeout = 300) => {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            func.apply(this, args);
        }, timeout);
    };
};

/**
 * Validates a LinkedIn profile URL.
 *
 * @param {string} URL - The LinkedIn profile URL to validate.
 * @return {boolean} Returns true if the URL is valid, false otherwise.
 */
export const validateLinkedInProfileURL = (URL) => {
    const regex = /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)\/([-a-zA-Z0-9]+)\/*/gm;

    if (regex.test(URL)) {
        return true;
    } else {
        return false;
    }
};
// If token not generated then pass the message.

// export const getAccountLink = async (accountName) => {
//   let SDKInstance = await getSDKInstance();
//   let workPlatforms = await SDKInstance.getAllWorkPlatforms();
//   if (Object.keys(workPlatforms) === 0) {
//     window.location.href = `/${SOMETHING_WENT_WRONG}`;
//   }
//   for (let key in workPlatforms.data) {
//     let workPlatformInstance = workPlatforms.data[key];
//     if (workPlatformInstance && workPlatformInstance.id === accountName) {
//       let response = await SDKInstance.getAuthorizations(
//         workPlatformInstance.id
//       );
//       if (Object.keys(response).keys() === 0) {
//         window.location.href = `/${SOMETHING_WENT_WRONG}`;
//       }
//       let url = response.authorization_url;
//       if (url) {
//         return url;
//       } else {
//         return "";
//       }
//     }
//   }
// };

// export const getAccount = async (accountName) => {
//   let SDKInstance = await getSDKInstance();
//   let workPlatforms = await SDKInstance.getAllWorkPlatforms();
//   for (let key in workPlatforms.data) {
//     let workPlatformInstance = workPlatforms.data[key];
//     if (workPlatformInstance && workPlatformInstance.id === accountName) {
//       return workPlatformInstance;
//     }
//   }

//   return "";
// };

// export const getAccountName = async (accountName) => {
//   let SDKInstance = await getSDKInstance();
//   let workPlatforms = await SDKInstance.getAllWorkPlatforms();
//   for (let key in workPlatforms.data) {
//     let workPlatformInstance = workPlatforms.data[key];
//     if (workPlatformInstance && workPlatformInstance.id === accountName) {
//       return workPlatformInstance.name;
//     }
//   }

//   return "";
// };

// export const getAccountId = async (accountName) => {
//   let SDKInstance = await getSDKInstance();
//   let workPlatforms = await SDKInstance.getAllWorkPlatforms();
//   for (let key in workPlatforms.data) {
//     let workPlatformInstance = workPlatforms.data[key];
//     if (
//       workPlatformInstance &&
//       workPlatformInstance.name.toLowerCase() === accountName
//     ) {
//       return workPlatformInstance.id;
//     }
//   }

//   return "";
// };

// export const getConfiguration = async () => {
//   let SDKInstance = await getSDKInstance();
//   let configuration = await SDKInstance.getConfiguration();
//   return configuration;
// };
