import React, { useEffect, useState } from "react";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { useLocation, useNavigate } from "react-router-dom";
import { CardContainer, PrimaryButton, ScreenNavbar } from "../../components";
import "./BeeHiivSelectAccount.scss";
import { BEEHIIV_SELECT_ACC_SCREEN } from "../../analytics/eventConstants";
import { SDKInstanceState } from "../../context/SdkInstanceContext";
import {
  SOMETHING_WENT_WRONG,
  STATUS_CONNECTED,
} from "../../constants/URLConstants";
import { WorkPlatformDetailsState } from "../../context/WorkPlatformDetailsContext";
import Loader from "../../components/Loader/Loader";

function BeeHiivSelectAccount() {
  const location = useLocation();
  const navigate = useNavigate();

  const accs = location?.state?.publications;
  const { apiKey } = location.state;
  const { authorization_id } = location.state;
  const { workPlatformId } = location.state;

  const { workplatformDetails, dispatchWorkPltfrmContext } =
    WorkPlatformDetailsState();
  const { sdkInstance, dispatchSdkInstContext } = SDKInstanceState();
  const [platformDetail, setPlatformDetail] = useState(
    workplatformDetails[workPlatformId]
  );

  const [selectedAccs, setSelectedAccs] = useState();

  const [isLoading, setIsLoading] = useState(false);

  const onProceedHandler = async () => {
    setIsLoading(true);
    let response = await sdkInstance.postConnectWithPlatform({
      workPlatformId,
      credentials: {
        api_key: apiKey,
      },
      platform_publication_ids: [selectedAccs],
      authorization_id,
    });
    if (response?.status === 200) {
      response = await response.json();
      const acc_ids = response.data.map((acc) => {
        return acc.id;
      });
      setIsLoading(false);
      navigate(`/${STATUS_CONNECTED}/${acc_ids}`);
    } else {
      setIsLoading(false);
      navigate(`/${SOMETHING_WENT_WRONG}`);
    }
  };

  const handleChangeAccs = (data) => {
    setSelectedAccs(data.target.value);
  };

  if (isLoading) {
    return <Loader />;
  }
  return (
    <div className="page-background beehiiv-select-acc-container">
      <CardContainer>
        <ScreenNavbar
          noBackButton
          screenName={BEEHIIV_SELECT_ACC_SCREEN}
          workplatformDetails={{
            workplatformId: platformDetail?.id,
            workplatformName: platformDetail?.name,
          }}
        />
        <div className="platform-title">
          <p>Select a newsletter </p>
        </div>
        <div className="platform-description">
          <p>
            Your Beehiiv account has multiple newsletters. Please select the one
            you would like to connect.{" "}
          </p>
        </div>
        <div className="accs-list-container">
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-form-control-label-placement"
              name="position"
              defaultValue="top"
            >
              {accs.map((acc) => (
                <div className="acc-container" key={acc.id}>
                  <FormControlLabel
                    classes={{
                      root: "radio-btn-label-container",
                      label: "radio-btn-label",
                    }}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      marginRight: "0px",
                    }}
                    value={acc.id}
                    control={
                      <Radio
                        disableRipple
                        classes={{
                          root: "radio-btn-container",
                          checked: "radio-btn-checked",
                        }}
                      />
                    }
                    label={acc.name}
                    labelPlacement="start"
                    onChange={handleChangeAccs}
                  />
                </div>
              ))}
            </RadioGroup>
          </FormControl>
        </div>
        <PrimaryButton
          onClick={onProceedHandler}
          style={selectedAccs ? {} : { opacity: 0.5 }}
          disabled={!selectedAccs}
          className="primary-btn"
          type="submit"
        >
          Proceed
        </PrimaryButton>
      </CardContainer>
    </div>
  );
}

export default BeeHiivSelectAccount;
