import { createContext, useContext, useReducer, useState } from "react";

export const Loader = createContext();

function LoaderContext({ children }) {
  const [loader, dispatchLoaderContext] = useState(true);

  return (
    <Loader.Provider value={{ loader, dispatchLoaderContext }}>
      {children}
    </Loader.Provider>
  );
}

export const LoaderState = () => useContext(Loader);

export default LoaderContext;
