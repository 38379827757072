export const facebookLocalization = {
    en: {
        "Connecting your account requires a Facebook business page": "Connecting your account requires a Facebook business page",
        "Do you have a Facebook business page?": "Do you have a Facebook business page?",
        Yes: "Yes",
        No: "No",
        "Facebook has the above requirement for a successful account connection":
            "Facebook has the above requirement for a successful account connection.",
        "How to create a Facebook business page?": "How to create a Facebook business page?",
    },
    id: {
        "Connecting your account requires a Facebook business page": "Menghubungkan akun Anda memerlukan halaman bisnis Facebook",
        "Do you have a Facebook business page?": "Apakah Anda memiliki halaman bisnis Facebook?",
        Yes: "Ya",
        No: "Tidak",
        "Facebook has the above requirement for a successful account connection": "Facebook memiliki persyaratan di atas agar koneksi akun berhasil",
        "How to create a Facebook business page?": "Bagaimana cara membuat halaman bisnis Facebook?",
    },
    ja: {
        "Connecting your account requires a Facebook business page": "アカウントを接続するには Facebook ビジネス ページが必要です",
        "Do you have a Facebook business page?": "Facebookのビジネスページはありますか？",
        Yes: "はい",
        No: "いいえ",
        "Facebook has the above requirement for a successful account connection":
            "Facebook では、アカウント接続を成功させるために上記の要件があります",
        "How to create a Facebook business page?": "Facebookのビジネスページを作成するにはどうすればよいですか?",
    },
    cn: {
        "Connecting your account requires a Facebook business page": "连接您的帐户需要 Facebook 业务页面",
        "Do you have a Facebook business page?": "您有 Facebook 企业页面吗？",
        Yes: "是",
        No: "否",
        "Facebook has the above requirement for a successful account connection": "Facebook 对于成功的帐户连接有上述要求",
        "How to create a Facebook business page?": "如何创建 Facebook 企业页面？",
    },
    zh: {
        "Connecting your account requires a Facebook business page": "连接您的帐户需要 Facebook 业务页面",
        "Do you have a Facebook business page?": "您有 Facebook 企业页面吗？",
        Yes: "是",
        No: "否",
        "Facebook has the above requirement for a successful account connection": "Facebook 对于成功的帐户连接有上述要求",
        "How to create a Facebook business page?": "如何创建 Facebook 企业页面？",
    },
    fr: {
        "Connecting your account requires a Facebook business page": "La connexion de votre compte nécessite une page professionnelle Facebook",
        "Do you have a Facebook business page?": "Avez-vous une page professionnelle Facebook?",
        Yes: "Oui",
        No: "Non",
        "Facebook has the above requirement for a successful account connection":
            "Facebook a les exigences ci-dessus pour une connexion de compte réussie",
        "How to create a Facebook business page?": "Comment créer une page entreprise Facebook ?",
    },
    "zh-tw": {
        "Connecting your account requires a Facebook business page": "連接您的帳戶需要 Facebook 商業頁面",
        "Do you have a Facebook business page?": "您有 Facebook 企業專頁嗎？",
        Yes: "是的",
        No: "不",
        "Facebook has the above requirement for a successful account connection": "Facebook 對於成功的帳號連結有上述要求",
        "How to create a Facebook business page?": "如何建立 Facebook 企業專頁？",
    },
    es: {
        "Connecting your account requires a Facebook business page": "Conectar su cuenta requiere una página comercial de Facebook",
        "Do you have a Facebook business page?": "¿Tienes una página comercial en Facebook?",
        Yes: "Sí",
        No: "No",
        "Facebook has the above requirement for a successful account connection":
            "Facebook tiene el requisito anterior para una conexión de cuenta exitosa",
        "How to create a Facebook business page?": "¿Cómo crear una página empresarial en Facebook?",
    },
    pt: {
        "Connecting your account requires a Facebook business page": "Conectar sua conta requer uma página comercial do Facebook",
        "Do you have a Facebook business page?": "Você tem uma página comercial no Facebook?",
        Yes: "Sim",
        No: "Não",
        "Facebook has the above requirement for a successful account connection":
            "O Facebook tem o requisito acima para uma conexão de conta bem-sucedida",
        "How to create a Facebook business page?": "Como criar uma página comercial no Facebook?",
    },
};
